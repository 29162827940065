import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import actions from 'actions';
import api from 'api';


export function usePrev ( value )
{
	const prev = useRef();

	useEffect (
		() => {
			prev.current = value;
		},
		[ value ]
	);

	return prev.current;
}

export function usePhotoLoader ( ids )
{
	const timer = useRef ( null ),
		[ waiter, setWaiter ] = useState ( false ),
		[ data, setData ] = useState ( {} ),
		request = useCallback (
			( ids, status ) => {
				if ( status.canceled )
				{
					clearTimeout ( timer.current );
					setData ( {} );
					setWaiter ( false );
				}
				else
				{
					setWaiter ( true );

					api.web.getPhotoFiles ( ids )
						.then ( ({ error, photoFiles = {} }) => {
							if ( status.canceled ) return;

							if (
								error ||
								ids.find (
									photoId => {
										const { loading, error, url } = photoFiles[ photoId ] || {};

										return loading || error || !url;
									}
								)
							) timer.current = setTimeout ( () => request ( ids, status ), 1000 );
							else if ( timer.current !== false )
							{
								setData ( photoFiles );
								setWaiter ( false );
							}
						} );
				}
			},
			[]
		);

	useEffect (
		() => () => {
			clearTimeout ( timer.current );
			timer.current = false;
		},
		[]
	);

	useEffect (
		() => {
			const status = { canceled: false };

			if ( ids.length ) request ( ids, status );
			else
			{
				setData ( {} );
				setWaiter ( false );
			}

			return () => status.canceled = true;
		},
		// eslint-disable-next-line
		[ ids ]
	);

	return useMemo (
		() => [ waiter, data ],
		[ waiter, data ]
	);
}

export function useRequest ( methodName, args )
{
	const [ response, setResponse ] = useState ( {} ),
		[ waiter, setWaiter ] = useState ( false ),
		request = useCallback (
			( status, methodName, args ) => {
				if ( status.canceled ) return;

				setWaiter ( true );

				api.web[ methodName ]( ...args )
					.then ( response => {
						if ( status.canceled ) return;

						setResponse ( response );
						setWaiter ( false );
					} );
			},
			[]
		),
		refreshStarterRef = useRef ( false ),
		[ refreshStarter, setRefreshStarter ] = useState ( false ),
		refresh = useCallback (
			() => {
				refreshStarterRef.current = !refreshStarterRef.current;

				setRefreshStarter ( refreshStarterRef.current );
			},
			[]
		);

		useEffect (
			() => {
				const status = { canceled: false };

				request ( status, methodName, args );

				return () => status.canceled = true;
			},
			// eslint-disable-next-line
			[ ...args, methodName, refreshStarter ]
		);

	return useMemo (
		() => [ response, waiter, refresh ],
		[ response, waiter, refresh ]
	);
}

export function useRequestTableData ( isGroup = false )
{
	return useSelector (
		({ table, points: { date }, filters: { values: filterValues } }) => ({
			date: ( table && table.date ) || date,
			filterValues,
			isGroup
		}),
		shallowEqual
	);
}

export function useRefreshTableCallback ()
{
	const dispatch = useDispatch(),
		{ highlight, tableType } = useSelector ( ({ table: { highlight, tableType } = {} }) => ({ highlight, tableType }), shallowEqual ),
		requestData = useRequestTableData ( highlight && highlight.isGroup ),
		{ groups, ids } = useSelector ( st => st.managers ),
		{ changed } = useSelector ( st => st.filters ),
		{ closed } = useSelector ( st => st.table );
		
	if ( !highlight && changed) {
		return () => {
			dispatch ( actions.updatedFilters ( ) );
		}
	}

	if ( !highlight ) return;

	const { isGroup, groupId, managerId } = highlight,
	payload = { ...requestData, groupId, tableType };

	if ( isGroup )
	{
		const group = groups.find ( ({ id }) => id === groupId ),
			selectedIds = group.managers.filter ( m => ids.includes ( m.id ) ).map ( m => m.id );

		if ( !selectedIds.length ) return;

		payload.ids = selectedIds
	}
	else payload.id = managerId;

	return () => { 
		!closed && dispatch ( actions.tableRequest ( payload ) );
		changed && dispatch ( actions.updatedFilters ( ) );
	}
}
