import randomColor from 'randomcolor';
import md5 from 'blueimp-md5';
import store from 'reduxStore';
import actions from 'actions';
const propList = [
	'id',
	'manager',
	'managerID',
	'name',
	'address',
	'opened',
	'closed',
	'code'
];


export function formatDuration ( seconds )
{
	const hours = Math.floor ( seconds / 3600 ),
		mins = Math.floor ( ( seconds - hours * 3600 ) / 60 ),
		sec = seconds % 60;

	return ( hours ? hours + ':' + ( '0' + mins ).slice ( -2 ) : mins ) +
		':' + ( '0' + sec ).slice ( -2 );
}

export function formatPrice ( price, unit = '' )
{
	return String ( Math.round ( price ) || '-' ).replace ( /(.)(?=(\d{3})+$)/g, '$1 ' ) + unit;
}

export function getColorById ( id )
{
	return randomColor ({ seed: md5 ( id ).slice ( 0, 8 ), luminosity: 'bright' })
}

export function compareStr ( a, b )
{
	return String ( a ).localeCompare ( b );
}

export function prepareFilters ( values )
{
	return Object.keys ( values ).reduce (
		( res, name ) => {
			const current = values[ name ];

			if ( name === 'visited' ) return { ...res, visited: current };

			return !current ? res : { ...res, [ name ]: current.map ? current.map ( ({ value }) => value ) : current };
		},
		undefined
	);
}

export function isManagerAllPoints ( ids, range )
{
	if (Array.isArray(range) && range.length > 0) {
		if (Array.isArray(ids) && ids.length > 1) {
			return false;
		}
	}
	return true;
}

export function openPanel ( properties, reopenOnlyMode )
{
	if ( reopenOnlyMode && !store.getState().panel ) return;

	const data = propList.reduce (
		( res, name ) => ({ ...res, [ name ]: properties.get ( name ) }),
		{}
	);

	store.dispatch ( actions.openPanel ( data ) );
}

export function objToURIParamString ( params )
{
	const pairs = [];

	for ( let name in params )
	{
		let value = params[ name ];


		if ( value !== undefined && value !== '' ) {
			if (Array.isArray(value)) {
				value.forEach(element => pairs.push ( `${ name }[]=${ typeof value === 'object' ? element : element }` ));
			} else {
				pairs.push ( `${ name }=${ typeof value === 'object' ? JSON.stringify ( value ) : value }` );
			}
		}
	}

	return pairs.length ? '?' + pairs.join ( '&' ) : '';
}
