import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


export default React.memo ( SelectDialog );

function SelectDialog ({ title, open, options, value: current, onSelect, onClose })
{
	const classes = useStyles();

	return (
		<Dialog {...{ open, onClose }}>
			<DialogTitle>{ title }</DialogTitle>

			<DialogContent>
				{ options.map (
					({ label, value }) => (
						<Button
							key={ value }
							className={ classes.button }
							variant="outlined"
							onClick={ () => onSelect ( value ) }
							color={ value === current ? 'primary' : undefined }
						>
							{ label }
						</Button>
					)
				) }
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={ onClose }>
					Отмена
				</Button>
			</DialogActions>
		</Dialog>
	);
}


const useStyles = makeStyles ( theme => ({
	button: {
		borderRadius: '20px',
		display: 'flex',
		width: '100%',
		marginBottom: 12,
		'&:last-child': {
			marginBottom: 0
		}
	}
}) );
