import moment from 'moment';
import clsx from 'clsx';
import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { DatePicker } from 'components';
import { withStyles } from '@material-ui/styles';

const styles = {
	button: {
		height: 36,
		margin: [[ 0, 20, 12, 20 ]],
		borderRadius: 20,
		border: '1px solid rgb(237, 237, 237)',
		padding: 0,
		color: 'rgb(149, 149, 149)',
		fontSize: 12
	}
};


export default withStyles ( styles )( Calendar );

function Calendar ({ classes, date, setDate, minDate, maxDate, placeholder, disabled, buttonClassName })
{
	const [ open, setOpen ] = useState ( false ),
		handleClick = useCallback (
			() => setOpen ( true ),
			[ setOpen ]
		),
		onClose = useCallback (
			() => setOpen ( false ),
			[ setOpen ]
		);

	return (
		<React.Fragment>
			<Button className={ clsx ( classes.button, buttonClassName ) } onClick={ handleClick } {...{ disabled }}>
				{ date.from && date.to ? moment ( date.from ).format ( 'D MMMM' ) + ' - ' + moment ( date.to ).format ( 'D MMMM' ) : placeholder }
			</Button>
			<DatePicker isRange {...{ open, date, minDate, maxDate, setDate, onClose }} />
		</React.Fragment>
	);
}
