import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
const Button = withStyles ({
	root: {
		zIndex: 1,
		position: 'absolute',
		bottom: 8,
		left: 8,
		color: '#828282',
		'& .copyright': {
			color: '#0077E7'
		},
		border: '1px solid #0077E7',
		backgroundColor: 'transparent',
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#0077E7',
			color: '#FFF',
			'& .copyright': {
				color: '#FFF'
			}
		}
	}
})( MuiButton );


export default React.memo ( Copyright );

function Copyright ({ className })
{
	return (
		<Button
			href="https://metasharks.ru/"
			target="_blank"
			rel="noopener noreferrer"
			variant="contained"
			size="small"
			{...{ className }}
		>
			Разработано&nbsp;<span className="copyright">metasharks.studio</span>
		</Button>
	);
}
