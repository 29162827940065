import { Table } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	({ table, points: { waiter }, panel }) => table ? ({ ...table, pointsWaiter: !!waiter, panel: !!panel }) : ({ closed: true }),
	dispatch => ({
		closeTable: () => dispatch ( actions.tableClose () )
	})
)( Table );
