import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles ( ({ palette }) => ({
	wrapper: {
		cursor: 'pointer',
		userSelect: 'none',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 18,
		height: 18
	},
	dot: {
		width: 4,
		height: 4,
		borderRadius: 2,
		backgroundColor: ({ disabled }) => disabled ? palette.action.disabled : palette.primary.main
	}
}) );


export default React.memo ( DotButton );

function DotButton ({ className, disabled, onClick })
{
	const classes = useStyles ({ disabled });

	return (
		<IconButton
			color="primary"
			{...{ className, disabled, onClick }}
		>
			<div className={ classes.wrapper }>
				<div className={ classes.dot } />
				<div className={ classes.dot } />
				<div className={ classes.dot } />
			</div>
		</IconButton>
	);
}
