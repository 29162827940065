export const
	RESET = 'RESET',
	AUTH = 'AUTH',
	AUTH_REQUEST = 'AUTH_REQUEST',
	CONFIRM = 'CONFIRM',
	CONFIRM_REQUEST = 'CONFIRM_REQUEST',
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	GET_GROUPS = 'GET_GROUPS',
	SET_GROUPS = 'SET_GROUPS',
	SELECT_MANAGERS = 'SELECT_MANAGERS',
	POINTS = 'POINTS',
	POINTS_REQUEST = 'POINTS_REQUEST',
	REQUEST_POINTS_BACKGROUND = 'REQUEST_POINTS_BACKGROUND',
	POINTS_BACKGROUND = 'POINTS_BACKGROUND',
	SET_BACKGROUND = 'SET_BACKGROUND',
	POINTS_SET_DATE = 'POINTS_SET_DATE',
	SET_TABLE_POINT = 'SET_TABLE_POINT',
	SET_USER = 'SET_USER',
	OPEN_PANEL = 'OPEN_PANEL',
	CLOSE_PANEL = 'CLOSE_PANEL',
	TOGGLE_PHOTO_MODAL = 'TOGGLE_PHOTO_MODAL',
	INIT_FILTERS_REQUEST = 'INIT_FILTERS_REQUEST',
	INIT_FILTERS = 'INIT_FILTERS',
	SET_FILTERS = 'SET_FILTERS',
	RESET_FILTERS = 'RESET_FILTERS',
	UPDATED_FILTERS = 'UPDATED_FILTERS',
	SET_FILTERS_INDICATOR = 'SET_FILTERS_INDICATOR',
	TABLE = 'TABLE',
	TABLE_CLOSE = 'TABLE_CLOSE',
	TABLE_REQUEST = 'TABLE_REQUEST',
	SET_TABLE_POINT_CONTROLLER_WAITER = 'SET_TABLE_POINT_CONTROLLER_WAITER',
	TABLE_SET_DATE = 'TABLE_SET_DATE',
	ERROR = 'ERROR',
	CLEAR_LAST_SELECTED = 'CLEAR_LAST_SELECTED',
	SET_LAST_SELECTED = 'SET_LAST_SELECTED';
