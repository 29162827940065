import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FigmaIcon } from 'components';
const useStyles = makeStyles ( theme => ({
	button: {
		color: '#FFF',
		height: 16,
		minWidth: 28,
		padding: 0
	},
	icon: {
		fontSize: 16,
		margin: -10
	}
}) );


export default React.memo ( ArrowButton );

function ArrowButton ({ className, ...props })
{
	const classes = useStyles();

	return (
			<Button
				className={ clsx ( classes.button, className ) }
				color="primary"
				variant="contained"
				size="small"
				{ ...props }
			>
				<FigmaIcon className={ classes.icon } name="arrow" />
			</Button>
	);
}
