import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import svg from './svg';


export default React.memo ( FigmaIcon );

function FigmaIcon ({ name, ...props })
{
	const { d, ...other } = svg[ name ];

	return (
		<SvgIcon { ...other } { ...props }>
			{ [].concat ( d ).map ( ( d, i ) => <path key={ i } {...{ d }} /> ) }
		</SvgIcon>
	);
}
