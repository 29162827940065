import React from 'react';
import { Main } from 'components';
import { Auth } from 'containers';


export default React.memo ( Pages );

function Pages ({ authorized })
{
	return ( authorized ?
		<Main />
		:
		<Auth />
	);
}
