import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ArrowButton, OrdersCountBadge } from 'components';
import { formatPrice, formatDuration } from 'functions';
import { SelectTable } from 'context';
import { useRequestTableData } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'actions';
import moment from 'moment';

const useStyles = makeStyles ( theme => ({
	head: {
		borderBottomWidth: 2
	},
	cell: {
		padding: 12,
		overflow: 'hidden',
	},
	descriptionContainer: {
		lineHeight: '14px',
		margin: [[ -5, 0 ]]
	},
	description: {
		fontSize: 12,
		color: '#A4A4A4'
	},
	link: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	pdz: {
		color: theme.palette.common.red
	},
	ordersPointCount: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	grey: {
		color: '#B6B6B6'
	},
	yellow: {
		color: theme.palette.common.yellow
	},
	green: {
		color: theme.palette.common.green
	},
	blue: {
		color: '#0066FF'
	},
	nowrap: {
		whiteSpace: 'nowrap'
	},
	wrap: {
		overflowWrap: 'break-word'
	},
	hasPhoto: {
		marginTop: 4
	},
	photo: {
		marginTop: 4
	},
	visited: {
		backgroundColor: '#FFFBD7'
	},
	nearby: {
		backgroundColor: '#E3F6EB'
	},
	totals: {
		fontSize: 11
	}
}) );


export default React.memo ( Row );

function Row ({ isRange, isGroup, isHead, row = {}, names, managerId, groupId, pointsWaiter })
{
	const classes = useStyles(),
		dispatch = useDispatch(),
		request = useSelector ( st => ( st.table && st.table.request ) || false ),
		requestData = useRequestTableData(),
		tableRequest = useCallback (
			data => dispatch (
				actions.tableRequest ({ ...requestData, ...data })
			),
			[ requestData, dispatch ]
		),
		pointsSetDate = useCallback (
			date => dispatch (
				actions.pointsSetDate ( date )
			),
			[ dispatch ]
		),

		disabledManager = !( isGroup && row.visits && row.visits.all );

	return (
		<TableRow
			key={ ( isGroup ? row.managerID : row.pointCode ) || row.n }
			className={ clsx ({
				[ classes.visited ]: (row.visitTime || row.visitedTime) && !row.nearby,
				[ classes.nearby ]: (row.visitTime || row.visitedTime) && row.nearby,
				[ classes.head ]: isHead
			}) }
		>
			{ names.map ( ( name, i ) => {
				if ( row.colspan && i > 0 && i < row.colspan ) return null;

				const colSpan = ( i === 0 && row.colspan ) || undefined,
					current = row[ name ],
					isYellow = !isHead && (row.orders.notOnPoint > 0) && row.nearby,
					isGreen = !isHead && (row.orders.onPoint > 0) && row.nearby;

				return (
					<TableCell
						key={ name }
						className={ clsx ({
							[ classes.cell ]: true,
							[ classes.nowrap ]: name !== 'name',
							[ classes.wrap ]: name === 'name',
							[ classes.head ]: !!row.colspan
						}) }
						align={ name === 'name' || name === 'date' || colSpan ? 'left' : 'center' }
						{...{ colSpan }}
					>
						{ colSpan ?
							<b className={ classes.totals }>Итого</b> :
							name === 'n' ?
							// Номер
							( row.n + 1 ):
							// Имя / Наименование
							name === 'name' ?
							<div className={ clsx ({ [ classes.descriptionContainer ]: !!row.description }) }>
								<SelectTable.Consumer>
									{ openSelectTableDialog =>
										<span
											className={ clsx ({ [ classes.link ]: !disabledManager }) }
											onClick={ disabledManager ? undefined :
												isRange ? () => openSelectTableDialog ({ ...requestData, id: row.managerID, groupId, backButton: request }) :
													() => tableRequest ({ id: row.managerID, groupId, backButton: request })
											}
										>
											{ current }
										</span>
									}
								</SelectTable.Consumer>
								{ !!row.description &&
									<React.Fragment>
										<br />
										<span className={ classes.description }>{ row.description }</span>
									</React.Fragment>
								}
							</div> :
							name === 'date' ?
							// Дата
							<span
								className={ classes.link }
								onClick={ () => {
									const date = moment ( current, 'DD.MM.YYYY' ).toDate();

									tableRequest ({
										id: managerId,
										groupId,
										tableType: undefined,
										backButton: request,
										date
									});

									pointsSetDate ( date );
								} }
							>
								{ current }
							</span> :
							// Время визита
							name === 'visitTime' ?
							( current ? moment ( current ).format ( 'H:mm:ss' ) : '-' ) :
							name === 'visitSeconds' ?
							( current ? formatDuration ( current ) : '-' ) :
							name === 'workingSeconds' ?
							( current ? formatDuration ( current ) : '-' ) :
							// Суммы
							name === 'sum' ?
							<span className={ clsx ( classes[ name ], {
								[ classes.green ]: isGreen,
								[ classes.yellow ]: isYellow
							} ) }>
								<b>{ formatPrice ( current ) }</b>
								{/* <span className={ classes.grey }>&nbsp;₽</span> */}
							</span> :
							name === 'ordersPointCount' ?
							// ТП, кол-во
							<div className={ classes[ name ] }>
								<OrdersCountBadge orderType="point" count={ current || 0 } />
							</div> :
							// Задачи
							name === 'tasks' ?
							<span>
								<b className={ classes.green }>{ ( current || {} ).closed || 0 }</b>
								/
								<b className={ classes.pdz }>{ ( current || {} ).opened || 0 }</b>
							</span> :
							// Фото
							name === 'photo' ?
							<b>{ current || 0 }</b> :
							// Заказы
							name === 'ordersCount' ?
							<span>
								<b className={ classes.green }>{ ( row['orders'] || {} ).onPoint.count }</b>
								/
								<b className={ classes.yellow }>{ ( row['orders'] || {} ).notOnPoint.count }</b>
							</span> :
							// DN
							name === 'dn' ?
								( current || 0 ) :
							// Клиенты
							name === 'clients' ?
							<span>
								<b className={ classes.green }>{ ( current || {} ).nearby || 0 }</b>
								/
								<b>{ ( current || {} ).visited || 0 }</b>
								/
								<b>{ ( current || {} ).all || 0 }</b>
							</span> :
							// Посещения
							name === 'visits' ?
							<span>
								<b className={ classes.green }>{ ( current || {} ).nearby || 0 }</b>
								/
								<b>{ ( current || {} ).visited || 0 }</b>
								/
								<b>{ ( current || {} ).all || 0 }</b>
							</span> :
							// Сумма в точке
							name === 'onPointCount' ?
							<span>
								{ current || 0 }
							</span> :
							// Совместные визиты
							name === 'togetherVisits' ?
							<span>
								{ current || 0 }
							</span> :
							// В точке
							name === 'onPoint' ?
							<span>
								<b className={ classes.green }>{ formatPrice ( ( row['orders'] || 0 )[ name ].sum ) }</b>
							</span> :
							// Не в точке
							name === 'notOnPoint' ?
							<span>
								<b className={ classes.yellow }>{ formatPrice ( ( row['orders'] || 0 )[ name ].sum ) }</b>
							</span> :
							// Перейти
							name === 'pointCode' ? (
								row.colspan ? '' :
								<ArrowButton
									disabled={ pointsWaiter }
									onClick={ () => dispatch (
										actions.setTablePoint ({ code: current, pointId: row.pointId, managerId })
									) }
								/>
							) :
							String ( current === undefined || current === null ? '' : current )
						}
					</TableCell>
				);
			} ) }
		</TableRow>
	);
}
