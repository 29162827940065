import { createActions, handleActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	managersMap: {},
	groups: [],
	selected: {},
	lastSelected: {
		id: null,
		groupId: null,
	},
	ids: [],
	all: false,
	includeBase: false,
	waiter: true,
	error: false
};


export const actions = createActions ({
	[ actionTypes.GET_GROUPS ]: filter => ({ filter }),
	[ actionTypes.SET_GROUPS ]: ( groups, count, selected, error = false ) => ({ groups, count, selected, error }),
	[ actionTypes.SELECT_MANAGERS ]: ( groupId, id, all, excludeBase, preventAutoZoom = false ) => ({ groupId, id, all, excludeBase, preventAutoZoom }),
	[ actionTypes.CLEAR_LAST_SELECTED ]: (lastSelected) => ({ lastSelected }),
	[ actionTypes.SET_LAST_SELECTED ]: (payload) => payload
});

export default handleActions (
	{
		[ actionTypes.LOGOUT ]: () => defaultState,
		[ actionTypes.GET_GROUPS ]: state => ({ ...state, waiter: true }),
		[ actionTypes.SET_GROUPS ]: ( state, { payload } ) => ({ ...state, ...payload, managersMap: defaultState.managersMap, waiter: false }),
		[ actionTypes.SELECT_MANAGERS ]: ( state, action ) => correctSelectedManagersByDisabled ( selectManagersReducer ( state, action ) ),
		[ actionTypes.LOGOUT ]: () => defaultState,
		[ actionTypes.POINTS ]: ( state, { payload: { managersMap } } ) => correctSelectedManagersByDisabled ({ ...state, managersMap }),
		[ actionTypes.POINTS_BACKGROUND ]: ( state, { payload: { managersMap } } ) => correctSelectedManagersByDisabled ({ ...state, managersMap }),
		[ actionTypes.CLEAR_LAST_SELECTED ]: ( state) => clearLastSelectedReducer({ ...state }),
		[ actionTypes.SET_LAST_SELECTED ]: ( state, { payload }) => setLastSelectedReducer({ ...state }, payload),
	},
	defaultState 
);

function clearLastSelectedReducer({ lastSelected, ...other })
{
	return {
		...other,
		lastSelected: defaultState.lastSelected,
	};
}

function setLastSelectedReducer({ lastSelected, ...other }, payload)
{
	return {
		...other,
		lastSelected: payload
	};
}

function selectManagersReducer ( { selected, lastSelected, ids, ...other }, { payload: { groupId, id, all, excludeBase, preventAutoZoom } } )
{
	if ( typeof excludeBase === 'boolean' ) return { ...other, selected, ids, includeBase: !excludeBase };
	else if ( typeof all === 'boolean' )
	{
		const res = {},
			set = new Set();

		Object.keys ( selected ).forEach ( groupId => {
			res[ groupId ] = { incomplete: false };

			Object.keys ( selected[ groupId ] ).forEach ( id => {
				res[ groupId ][ id ] = all;

				if ( all && isId ( id ) ) set.add ( id );
			} );
		} )

		return {
			...other,
			selected: res,
			ids: all ? [ ...set ] : [],
			all,
			includeBase: false
		};
	}
	else
	{
		const set = new Set ( ids ),
			group = id === 'all' ? selectGroup ( selected[ groupId ], set ) : {
			...selected[ groupId ],
			[ id ]: !selected[ groupId ][ id ]
		};

		if ( isId ( id ) )
		{
			!selected[ groupId ][ id ] ? set.add ( id ) : set.delete ( id );
			lastSelected = !selected[ groupId ][ id ] ? {
				groupId, 
				id
			} : defaultState.lastSelected;

			const keys = Object.keys ( group );

			group.all = !keys.filter ( id => isId ( id ) && group[ id ] === false ).length;
			group.incomplete = !group.all && !!keys.find ( id => isId ( id ) && group[ id ] === true );
		}

		selected = { ...selected, [ groupId ]: group };

		return {
			...other,
			selected,
			lastSelected: lastSelected,
			ids: [ ...set ],
			all: !Object.keys ( selected ).filter ( groupId => !selected[ groupId ].all ).length,
			includeBase: false
		};
	}

	function selectGroup ( group, set )
	{
		const status = !group.all;

		return Object.keys ( group ).reduce (
			( res, id ) => {
				if ( isId ( id ) ) status ? set.add ( id ) : set.delete ( id );

				return { ...res, [ id ]: !group.all };
			},
			{ incomplete: false }
		);
	}
}

function isId ( id )
{
	return id !== 'all' && id !== 'incomplete';
}

function correctSelectedManagersByDisabled ( state )
{
	const { managersMap } = state,
		selected = { ...state.selected },
		ids = [];

	let totalManagers = 0,
		totalSelectedManagers = 0,
		groupCount = 0,
		groupAllCount = 0;

	Object.keys ( selected ).forEach ( groupId => {
		let count = 0,
			selectedCount = 0;

		Object.keys ( selected[ groupId ] ).forEach ( id => {
			if ( !isId ( id ) ) return;
			else if ( !managersMap[ id ] /* managersMap[ id ].disabled || managersMap[ id ].hidden */ ) selected[ groupId ][ id ] = false;
			else
			{
				if ( selected[ groupId ][ id ] )
				{
					selectedCount++;

					ids.push ( id );
				}
				count++;
			}
		})

		if ( selectedCount )
		{
			if ( selectedCount === count )
			{
				selected[ groupId ] = { ...selected[ groupId ], all: true, incomplete: false };
				groupAllCount++;
			}
			else selected[ groupId ] = { ...selected[ groupId ], incomplete: true, all: false }
		}
		else selected[ groupId ] = { ...selected[ groupId ], incomplete: false, all: false };

		totalManagers += count;
		totalSelectedManagers += selectedCount;

		if ( count ) groupCount++;
	} );

	const all = !!groupCount && groupCount === groupAllCount,
		includeBase = state.includeBase && totalSelectedManagers === 1;

	return { ...state, selected, ids, all, count: totalManagers, includeBase };
}
