import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Photo } from 'components';
import moment from 'moment';
const useStyles = makeStyles ({
	root: ({ first }) => ({
		margin: [[ first ? 0 : 12, 40, 0, 40 ]]
	}),
	fs12: {
		fontSize: 12
	},
	badge: {
		display: 'inline-block',
		width: 88,
		height: 22,
		textAlign: 'center',
		backgroundColor: '#22B0E0',
		color: '#FFF',
		borderRadius: 3,
		padding: 2,
		marginRight: 8
	},
	opened: {
		backgroundColor: '#B1B1B1'
	},
	completed: {
		backgroundColor: '#53AE82'
	},
	rejected: {
		backgroundColor: '#E51A1A'
	},
	date: {
		color: '#8F8F8F'
	},
	comment: {
		padding: 12,
		backgroundColor: '#FFF',
		border: '1px solid #E7E7E7',
		color: '#8F8F8F',
		borderRadius: 3,
		marginTop: 12
	},
	photo: {
		marginTop: 12
	},
	photoContainer: {
		transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		overflow: 'hidden',
		height: 62
	},
	collapsed: {
		height: 0,
		transitionDuration: '269ms'
	},
	taskSteps: {
		display: 'flex',
		flexDirection: 'column',
	},
	tasksRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	author: {
		fontSize: 12,
		color: '#030303',
	}
});


export default React.memo ( TaskRow );

function TaskRow ({ first, status, timestamp, comment, photo, expanded, author })
{
	const classes = useStyles ({ first });

	status = status === 'ВРаботе' ? 'В работе' : status;

	return (
		<div className={ classes.root }>
				<div className={ classes.tasksRow }>
					<span className={ clsx ( classes.badge, {
						[ classes.opened ]: status === 'Открытая',
						[ classes.completed ]: status === 'Выполненная',
						[ classes.rejected ]: status === 'Отказ'
					} ) }>
						{ status }
					</span>
					<span className={ classes.taskSteps }>
						{
							author && <span className={ classes.author }>{author}</span>
						}
						<span className={ classes.date }>{ moment ( timestamp ).format ( 'DD.MM.YYYY в HH:mm:ss' ) }</span>
					</span>
				</div>
			{ !!comment &&
				<Typography className={ classes.comment }><i>{ comment }</i></Typography>
			}
			{ !!photo &&
				<div className={ clsx ( classes.photoContainer, {
					[ classes.collapsed ]: first && !expanded
				} ) }>
					<Photo
						className={ classes.photo }
						photoId={ photo }
					/>
				</div>
			}
		</div>
	);
}
