import { PurePhoto } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	null,
	dispatch => ({
		openPhotoModal: photo => dispatch ( actions.togglePhotoModal ( photo ) )
	})
)( PurePhoto );
