import React, { useState, useCallback } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import { FigmaIcon, Waiter } from 'components';
import { Filters } from 'containers';
import { makeStyles } from '@material-ui/core/styles';


export default React.memo ( PointFilters );

function PointFilters ({ filters, disabledFilters, date, ids, indicator, waiter, controller })
{
	const classes = useStyles(),
		[ anchorEl, setAnchorEl ] = useState ( null ),
		open = !!anchorEl,
		onClick = useCallback (
			e => setAnchorEl ( e.currentTarget ),
			[ setAnchorEl ]
		),
		onClose = useCallback (
			() => setAnchorEl ( null ),
			[ setAnchorEl ]
		);

	if ( controller ) return null;

	return (
		<React.Fragment>
			<IconButton
				color={ indicator ? 'primary' : 'default' }
				{...{ onClick }}
			>
				<FigmaIcon name="filters" />
			</IconButton>
			<Popover
				key={ waiter }
				classes={{ paper: classes.popover }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				{...{ open, anchorEl, onClose }}
			>
				<div className={ classes.col }>
					<div>
						<Filters.Visited />
					</div>
					<Filters.Toggle />
				</div>
				<Filters.Dropdown />
				<Waiter show={ waiter } />
			</Popover>
		</React.Fragment>
	);
}


const useStyles = makeStyles ({
	popover: {
		padding: [[ 12, 0 ]],
		overflow: 'initial',
		display: 'flex'
	},
	col: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	label: {
		display: 'flex',
		margin: [[ -6, 16, -6, 4 ]]
	},
	button: {
		marginTop: 4,
		marginLeft: 10
	}
});
