import React, { useRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Calendar from './Calendar';


export default React.memo ( DatePicker );

function DatePicker ({ isRange, open, date, minDate, maxDate, preventClose, setDate, onClose })
{
	const dateRef = useRef ( date ),
		handleConfirm = useCallback (
			() => {
				const { current } = dateRef;

				if ( isRange )
				{
					if ( !( current.from && current.to ) )
					{
						return alert ( 'Период не выбран!' );
					}
				}
				else if ( !current )
				{
					return alert ( 'Дата не выбрана' );
				}

				onClose();

				setDate ( current );
			},
			[ isRange, onClose, setDate ]
		),
		onChange = useCallback (
			date => dateRef.current = date,
			[]
		);

	return (
		<Dialog
			onClose={ preventClose ? undefined : onClose }
			{...{ open }}
		>
			<Calendar initialDate={ date } {...{ isRange, minDate, maxDate, onChange }} />
			<DialogActions>
				{ !preventClose &&
					<Button color="primary" onClick={ onClose }>Отмена</Button>
				}
				<Button color="primary" onClick={ handleConfirm }>ОК</Button>
			</DialogActions>
		</Dialog>
	);
}
