import { all, fork } from 'redux-saga/effects';
import init from './init';
import login from './login';
import managers from './managers';
import points from './points';
import filters from './filters';
import table from './table';
import error from './error';


export default function* root ()
{
	yield all ( [
		init,
		login,
		managers,
		points,
		filters,
		table,
		error
	].map ( fork ) );
}
