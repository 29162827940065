import 'reactotron';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { MapComponentProvider } from 'mlk-ui-kit';
import App from './App';
import theme from 'theme';
import store from 'reduxStore';

import * as serviceWorker from './serviceWorker';

if ( process.env.NODE_ENV === 'development' ) console.log ( theme );

const render = Component => ReactDOM.render (
	<ThemeProvider {...{ theme }}>
		<Provider {...{ store }}>
			<MapComponentProvider>
				<Component />
			</MapComponentProvider>
		</Provider>
	</ThemeProvider>,
	document.getElementById ( 'root' )
);

render ( App );

if ( module.hot )
{
	module.hot.accept (
		'./App',
		() => render (
			require ( './App' ).default // Next App
		)
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
