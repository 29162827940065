import React from 'react';
import clsx from 'clsx';
import MuiIconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
const useStyles = makeStyles ({
		iconButton: {
			margin: -12,
			padding: 12
		},
		radioButton: {
			width: 16,
			height: 16,
			borderRadius: 8,
			border: ({ color = 'rgba(0, 0, 0, 0.54)' }) => `2px solid ${ color }`,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		check: {
			width: 10,
			height: 10,
			borderRadius: 5,
			transform: 'scale(0.65)',
			backgroundColor: ({ color = 'rgba(0, 0, 0, 0.54)' }) => color
		}
	}),
	IconButton = withStyles ({
		root: ({ htmlColor = 'rgba(0, 0, 0, 0.54)' }) => ({ color: htmlColor })
	})(
		({ htmlColor, ...props }) => <MuiIconButton { ...props } />
	);


export default React.memo ( RadioButton );

function RadioButton ({ className, primary, color, checked, onClick })
{
	const classes = useStyles ({ color });

	return (
		<IconButton
			className={ clsx ( classes.iconButton, className ) }
			htmlColor={ primary ? undefined : color }
			color={ primary ? 'primary' : undefined }
			{...{ onClick }}
		>
			<div className={ classes.radioButton }>
				{ checked &&
					<div className={ classes.check } />
				}
			</div>
		</IconButton>
	);
}
