import React, { useEffect, useState, useRef } from 'react';
import { PurePhoto } from 'containers';
import api from 'api';


export default React.memo ( Photo );

function Photo ({ photoId, className })
{
	const timer = useRef(),
		[ src, setSrc ] = useState(),
		request = ( status = { canceled: false } ) => {
			api.web.getPhotoFiles ( photoId )
				.then ( ({ photoFiles: { [ photoId ]: { loading, url, error: error2 } = {}, } = {}, error }) => {
					if ( status.canceled ) return;

					if ( error || error2 || loading || !url ) timer.current = setTimeout ( request, 1000, status );
					else if ( timer.current !== false ) setSrc ( url );
				} );

			return () => {
				status.canceled = true;
				clearTimeout ( timer.current );
				timer.current = false;
			}
		};

	useEffect (
		request,
		[]
	)

	return <PurePhoto {...{ className, src }} />;
}
