import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { RadioButton } from 'components';
const radio = [
		null,
		true,
		false
	],
	labels = [
		'Все',
		'Посещенные',
		'Не посещенные'
	],
	useStyles = makeStyles ({
		container: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: 16,
			marginBottom: 10
		},
		label: {
			margin: [[ -3, 12 ]]
		},
		radio: {
			margin: [ [ -4, 0, -4, -9 ], '!important' ]
		}
	});


export default React.memo ( Visited );

function Visited ({ values, setFilters })
{
	const classes = useStyles(),
		theme = useTheme();

	return (
		<div className={ classes.container }>
			{ radio.map ( ( name, i ) => (
				<FormControlLabel
					key={ name }
					className={ classes.label }
					control={
						<RadioButton
							className={ classes.radio }
							color={ values.visited === radio[ i ] ? theme.palette.primary.main : undefined }
							onClick={ () => setFilters ({ visited: radio[ i ] }) }
							checked={ values.visited === radio[ i ] }
							primary
						/>
					}
					label={ labels[ i ] }
				/>
			) ) }
		</div>
	);
}
