import React, { useRef, useMemo, useCallback, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Manager from './Manager';
import { FigmaIcon, DotButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestTableData } from 'hooks';
import actions from 'actions';
import IconButton from '@material-ui/core/IconButton';

const onClick = e => e.stopPropagation(),
	TransitionProps = { timeout: 0 },
	Accordion = withStyles ({
		root: {
			border: '1px solid rgba(0, 0, 0, .125)',
			borderLeft: 'none',
			borderRight: 'none',
			boxShadow: 'none',
			'&:not(:last-child)': {
				borderBottom: 0
			},
			'&:before': {
				display: 'none'
			},
			'&$expanded': {
				margin: 'auto'
			}
		},
		expanded: {}
	})( MuiAccordion ),

	AccordionSummary = withStyles ({
		root: {
			// backgroundColor: 'rgba(0, 0, 0, .03)',
			borderBottom: '1px solid rgba(0, 0, 0, .125)',
			marginBottom: -1,
			paddingLeft: 9,
			paddingRight: 12,
			minHeight: 56,
			'&$expanded': {
				minHeight: 56
			}
		},
		content: {
			'&$expanded': {
				margin: '12px 0'
			},
			alignItems: 'center'
		},
		expanded: {}
	})( MuiAccordionSummary ),

	AccordionDetails = withStyles ( theme => ({
		root: {
			padding: theme.spacing ( 2 )
		}
	}))( MuiAccordionDetails );


export default React.memo ( Group );

function Group ({
	isRange,
	tableType,
	groupId,
	name,
	expanded,
	managers,
	selected,
	managersMap,
	showIncludeBase,
	includeBase,
	handleChange,
	selectManagers
})
{
	const classes = useStyles(),
		dispatch = useDispatch(),
		dataRef = useRef ({ managers, selected }),
		highlighted = useSelector ( state => checkHighlighted ( state, groupId ) ),
		requestData = useRequestTableData ( true ),
		onChange = useMemo (
			() => handleChange ( groupId ),
			[ handleChange, groupId ]
		),
		onCheck = useCallback (
			() => selectManagers ( groupId, 'all' ),
			[ selectManagers, groupId ]
		),
		openTable = useCallback (
			e => {
				const { managers, selected, requestData } = dataRef.current,
					ids = managers.reduce (
						( res, { id } ) => selected[ id ] ? [ id, ...res ] : res,
						[]
					);

				dispatch (
					actions.tableRequest ({
						...requestData,
						tableType: isRange ? 'summary' : undefined,
						groupId,
						ids
					})
				);
				dispatch(actions.clearLastSelected());

				// prevent extra click
				e.stopPropagation();
				e.preventDefault();
			},
			[ groupId, isRange, dispatch ]
		),
		disabled = !( selected.all || selected.incomplete );

	useEffect (
		() => {
			dataRef.current = { managers, selected, requestData };
		},
		[ managers, selected, requestData ]
	);

	return (
		<Accordion key={ groupId } square {...{ expanded, TransitionProps, onChange }}>
			<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
				<div className={ classes.checkboxContainer }>
					<Checkbox
						color="primary"
						checked={ selected.all }
						onChange={ onCheck }
						icon={
							!selected.all && selected.incomplete ?
								<React.Fragment>
									<CheckBoxOutlineBlank />
									<FigmaIcon name="miniCheck" className={ classes.miniCheck } />
								</React.Fragment>
								:
								undefined
						}
						// disabled={ checkDisabled ( 'all', managersMap, managers ) }
						{...{ onClick }}
					/>
					<Typography><b>{ name }</b> ({ managers.length })</Typography>
				</div>
				{ 

					!!highlighted ? <IconButton
					className={ classes.iconButton }
					color="primary"
					onClick={ openTable }
					>
					<FigmaIcon name='refresh'  className={ classes.refreshIcon }/>
					</IconButton>
					:
					<DotButton
					onClick={ openTable }
					{...{ disabled } }
					/>
				}
			</AccordionSummary>
			<AccordionDetails>
				<div className={ classes.list }>
					{ managers.map ( ({ id, name }) =>
						<Manager
							key={ id }
							checked={ selected[ id ] }
							disabled={ checkDisabled ( id, managersMap, managers ) }
							showIncludeBase={ showIncludeBase && selected[ id ] }
							includeBase={ showIncludeBase && selected[ id ] && includeBase }
							data={ !!managersMap[ id ] }
							visited={ managersMap[ id ] && managersMap[ id ].visited }
							route={ managersMap[ id ] && managersMap[ id ].route }
							description={ managersMap[ id ] && managersMap[ id ].description }
							{...{ isRange, tableType, id, name, groupId, selectManagers }}
						/>
					) }
				</div>
			</AccordionDetails>
		</Accordion>
	);
}


const useStyles = makeStyles ( theme => ({
		list: {
			display: 'flex',
			flexDirection: 'column',
			// flex: 1
		},
		miniCheck: {
			position: 'absolute',
			color: theme.palette.primary.main
		},
		dotButton: {
			marginLeft: 'auto'
		},
		checkboxContainer: {
			flex: 1,
			display: 'flex',
			alignItems: 'center'
		},
		refreshIcon: {
			height: 16,
			width: 16
		},
	}) );

function checkDisabled ( id, managersMap, managers )
{
	if ( id !== 'all' ) return managersMap[ id ] && !!managersMap[ id ].disabled;
	else if ( !Object.keys ( managersMap ).length ) return false;

	return !managers.find ( ({ id }) => managersMap[ id ] && !managersMap[ id ].disabled );
}

function checkHighlighted ( { table }, id )
{
	if ( !table || !table.highlight ) return false;

	const { isGroup, groupId } = table.highlight;

	if ( isGroup && groupId === id ) return true;

	return false;
}
