import { Auth } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	({ user }) => ({ ...user }),
	dispatch => ({
		authRequest: phone => dispatch ( actions.authRequest ( phone ) ),
		confirmRequest: code => dispatch ( actions.confirmRequest ( code ) ),
		logout: () => dispatch ( actions.logout() )
	})
)( Auth );
