import React from 'react';
import { formatPrice } from 'functions';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
const useStyles = makeStyles ( theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		overflow: 'hidden'
	},
	header: {
		margin: [[ 15, 18 ]]
	},
	pdz: {
		fontSize: 25,
		fontWeight: 'bold'
	},
	right: {
		float: 'right'
	},
	red: {
		color: '#E51A1A'
	},
	grey: {
		color: '#B6B6B6',
		fontWeight: 'normal'
	},
	link: {
		color: '#22B0E0',
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		margin: [[ 0, 18, 18, 18 ]]
	},
	hr: {
		display: 'block',
		height: 1,
		border: 0,
		margin: 0,
		padding: 0,
		backgroundColor: '#E3E3E3'
	},
	img: {
		marginRight: 4
	},
	orders: {
		overflowY: 'scroll',
		flex: 1
	},
	order: {
		padding: [[ 14, 18, 12, 18 ]],
		borderBottom: '1px solid #E3E3E3'
	},
	row: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between'
	},
	date: {
		fontSize: 14,
		fontWeight: 'bold'
	},
	source: {
		fontSize: 12
	},
	manager: {
		fontSize: 12,
		marginLeft: '10px',
		marginBottom: '1px'
	},
	number: {
		fontSize: 14,
		color: '#8F8F8F'
	},
	sum: {
		fontSize: 20,
		fontWeight: 'bold',
		color: '#22B0E0'
	}
}) );


export default function Orders ({ statement, pdz, orders = [] })
{
	const classes = useStyles();

	return (
		<div className={ classes.root }>
			<div className={ classes.header }>
				<span className={ classes.pdz }>
					ПДЗ:
					<span className={ classes.right }>
						<span className={ classes.red }>{ formatPrice ( pdz ) }</span>
						<span className={ classes.grey }>&nbsp;₽</span>
					</span>
				</span>
			</div>
			{ !!statement &&
				<a className={ classes.link } href={ statement }>
					<img className={ classes.img } src='./statement.svg' alt="" />
					Скачать ведомость
				</a>
			}
			<hr className={ classes.hr } />
			<div className={ classes.orders }>
				{ orders.map ( ({ id, timestamp, timestamp_order, source, sum, manager = '' }) =>
					<div key={ id } className={ classes.order }>
						<div className={ classes.row }>
							<div className={ classes.row }>
								<span className={ classes.date }>{ moment ( 
									timestamp_order && 
									timestamp.split(' ')[0] !== timestamp_order.split(' ')[0] ? 
									timestamp_order : 
									timestamp 
								).format ( 'DD.MM.YYYY' ) }</span>
								<span className={ classes.manager }>{ manager }</span>
							</div>
							<span className={ classes.source }>{ source }</span>
						</div>
						{ timestamp_order && 
							timestamp.split(' ')[0] !== timestamp_order.split(' ')[0] &&
							<div className={ classes.row }>
								<div className={ classes.row }>
									<span className={ classes.date }>{ moment ( timestamp ).format ( 'DD.MM.YYYY' ) }</span>
								</div>
							</div>
						}
						<div className={ classes.row }>
							<span className={ classes.number }>Заказ { id }</span>
							<span className={ classes.sum }>
								<span>{ formatPrice ( sum ) }</span>
								<span className={ classes.grey }>&nbsp;₽</span>
							</span>
						</div>
					</div>
				) }
			</div>
		</div>
	);
}
