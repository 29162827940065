import { create } from 'apisauce';
import Reactotron from 'reactotron-react-js';
import { objToURIParamString } from 'functions';
import moment from 'moment';
const getDate = date => moment ( date ).format ( 'YYYY-MM-DD' ),
	errorResponse = { error: 'Ошибка в параметрах ответа сервера' };


const http = create ({
	baseURL: '/api/'
});

http.addResponseTransform ( response => {
	const { data = {}, problem, status } = response;

	let error = problem;

	if ( status === 401 ) error = 'Нет доступа';

	if ( !error )
	{
		if ( data.error || !data.ok ) error = data.error || problem || errorResponse.error;
	}

	if ( error ) response.error = error;
} );

if ( process.env.NODE_ENV === 'development' ) http.addMonitor ( Reactotron.apisauce );


export function setToken ( token )
{
	if ( token ) http.setHeaders ({ 'X-TOKEN': token });
	else delete http.headers[ 'X-TOKEN' ];
}

export function auth ( phone, device )
{
	return http.post ( 'auth', { phone, device } )
		.then ( getResult ( ({ device }) => ({ device }) ) )
		.catch ( error => ({ error }) );
}

export function confirm ( code, device )
{
	return http.post ( 'confirm', { code, device } )
		.then ( getResult ( ({ token }) => ({ token }) ) )
		.catch ( error => ({ error }) );
}

export function getManagers ()
{
	return http.get ( 'getManagers' )
		.then ( getResult ( ({ groups, count, user }) => ({ groups, count, user }) ) )
		.catch ( error => ({ error }) );
}

export function getPoints ( date, range, managers, all, filters, managerAllPoints )
{
	date = getDate ( date );

	let dates = Array.isArray(range) && range.length ? range.map(item => getDate(item)) : [];

	return http.post ( 'getPoints', { date, dates, managers, all, filters, managerAllPoints } )
		.then ( getResult ( ({ points, base, managersMap }) => ({ points, base, managersMap }) ) )
		.catch ( error => ({ error }) );
}

export function getOrders ( pointID )
{
	return http.get ( 'getOrders' + objToURIParamString ({ pointID }) )
		.then ( getResult ( ({ orders, statement, pdz }) => ({ orders, statement, pdz }) ) )
		.catch ( error => ({ error }) );
}

export function getTasks ( pointID, managerID, date, range )
{
	date = getDate ( date );

	let dates = Array.isArray(range) && range.length ? range.map(item => getDate(item)) : [];

	return http.get ( 'getTasks' + objToURIParamString ({ pointID, managerID, date, dates }) )
		.then ( getResult ( ({ tasks }) => ({ tasks }) ) )
		.catch ( error => ({ error }) );
}

export function getTogetherVisits ( pointID )
{
	return http.get ( 'getTogetherVisits' + objToURIParamString ({ pointID }))
		.then ( getResult ( ({ points }) => ({ points }) ) )
		.catch ( error => ({ error }) );
}

export function getMerches (pointID)
{
	return http.get ( 'getMerches' + objToURIParamString ({ pointID }))
		.then ( getResult ( ({ merches }) => ({ merches }) ) )
		.catch ( error => ({ error }) );
}

export function getPhoto ( pointID, managerID )
{
	return http.get ( 'getPhoto' + objToURIParamString ({ pointID, managerID }) )
		.then ( getResult ( ({ photo }) => ({ photo }) ) )
		.catch ( error => ({ error }) );
}

export function getPhotoByTasks ( pointID, managerID, date, range )
{
	date = getDate ( date );

	let dates = Array.isArray(range) && range.length ? range.map(item => getDate(item)) : [];
	return http.get ( 'getPhotoByTasks' + objToURIParamString ({ pointID, managerID, date, dates }) )
		.then ( getResult ( ({ tasks }) => ({ tasks }) ) )
		.catch ( error => ({ error }) );
}

export function getPhotoFiles ( photos )
{
	photos = [].concat ( photos ).join ( ',' );

	return http.get ( 'getPhotoFiles' + objToURIParamString ({ photos }) )
		.then ( getResult ( ({ photoFiles }) => ({ photoFiles }) ) )
		.catch ( error => ({ error }) );
}

export function getFilters ( date, range, managers )
{
	date = getDate ( date );

	let dates = Array.isArray(range) && range.length ? range.map(item => getDate(item)) : [];

	return http.post ( 'getFilters', { date, dates, managers } )
		.then ( getResult ([ 'tasks', 'regions' ]) )
		.catch ( error => ({ error }) );
}

export function getTable ( managerId, date, filters )
{
	date = getDate ( date );

	return http.post ( 'getTable', { managerId, date, filters } )
		.then ( getResult ( 'manager' ) )
		.catch ( error => ({ error }) );
}

export function getTableByDates ( managerId, dateFrom, dateTo, filters )
{
	dateFrom = getDate ( dateFrom );
	dateTo = getDate ( dateTo );

	return http.post ( 'getTableByDates', { managerId, dateFrom, dateTo, filters } )
		.then ( getResult ( 'manager' ) )
		.catch ( error => ({ error }) );
}

export function getTableByPoints ( managerId, dateFrom, dateTo, filters )
{
	dateFrom = getDate ( dateFrom );
	dateTo = getDate ( dateTo );

	return http.post ( 'getTableByPoints', { managerId, dateFrom, dateTo, filters } )
		.then ( getResult ( 'manager' ) )
		.catch ( error => ({ error }) );
}

export function getGroupTable ( groupId, date, managers, filters )
{
	date = getDate ( date );

	return http.post ( 'getGroupTable', { groupId, date, managers, filters } )
		.then ( getResult ( 'group' ) )
		.catch ( error => ({ error }) );
}

export function getGroupTableByPeriod ( groupId, dateFrom, dateTo, managers, filters )
{
	dateFrom = getDate ( dateFrom );
	dateTo = getDate ( dateTo );


	return http.post ( 'getGroupTablePeriod', { groupId, dateFrom, dateTo, managers, filters } )
		.then ( getResult ( 'group' ) )
		.catch ( error => ({ error }) );
}

// (fn|arr|str) => obj
function getResult ( fn = res => res )
{
	return ({ data, error }) => {
		if ( error ) return { error };

		if ( typeof fn === 'string' || Array.isArray ( fn ) )
		{
			const props = [].concat ( fn );

			fn = data => props.reduce (
				( res, prop ) => ({ ...res, [ prop ]: data[ prop ] }),
				{}
			);
		}

		return fn ( data ) || errorResponse;
	}
}
