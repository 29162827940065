import './points.css';
import React, { useRef, useEffect } from 'react';
import { ZoomControl, Clusterer, Placemark } from 'react-yandex-maps';
import { ToggleButton } from '@material-ui/lab';
import { Panel } from 'containers';
import { Waiter, Copyright } from 'components';
import { formatPrice, getColorById } from 'functions';
import getTemplates from './getTemplates';
import BoundsController from './BoundsController';
import TablePointController from './TablePointController';
import { makeStyles } from '@material-ui/styles';
import {
	useMainDrawer,
	MapWrapper,
	MapComponent,
	MapOptions,
	Icon,
	MapMarginArea,
	useMapComponent,
	useCloseBalloonOnMapClick,
	useMapOption
} from 'mlk-ui-kit';

const clustererOptions = {
		gridSize: 60,
		zoomMargin: 80,
		clusterIconShape: {
			type: 'Circle',
			coordinates: [ 30, 30 ],
			radius: 30
		},
		iconOffset: [ -30, -30 ],
		hideIconOnBalloonOpen: false,
		balloonPanelMaxMapArea: 0
	},
	useStyles = makeStyles ({
		waiter: ({ waiterMargin }) => ({
			marginRight: waiterMargin
		})
	});


export default function Points ({
	waiter,
	points,
	base,
	managersMap,
	ids,
	preventAutoZoom,
	background,
	filters,
	panel,
	tablePoint,
	requestPointsBackground,
	setBackground,
	setTablePointControllerWaiter
})
{
	const { opened: open } = useMainDrawer(),
		position = useMapOption ( 'position' ), // isToday === !moment().diff ( moment ( date ), 'days' )
		timer = useRef ( null ),
		clusterer = useRef ( null ),
		pointsRef = useRef ( {} ),
		{ ymapsRef, map, templates } = useMapComponent(),
		classes = useStyles ({
			waiterMargin: panel ? 320 : 0
		});

	useCloseBalloonOnMapClick();

	useEffect (
		() => {
			if ( background ) setBackground ( false );

			clearTimeout ( timer.current );

			timer.current = setTimeout (
				() => requestPointsBackground(),
				60000
			);

			return () => clearTimeout ( timer.current );
		},
		// eslint-disable-next-line
		[ points, base ]
	);

	useEffect (
		() => {
			if ( map ) setTimeout (
				() => map.container.fitToViewport(),
				open ? 200 : 0
			);
		},
		// eslint-disable-next-line
		[ open ]
	);

	useEffect (
		() => {
			if ( clusterer.current )
			{
				const basePoints = base.reduce ( ( res, data ) => {
					const {
						geometry,
						properties,
						options
					} = getPointProps ( data, managersMap[ data.managerID ], 'base', templates );

					if ( geometry[ 0 ] && geometry[ 1 ] )
					{
						res.push (
							new ymapsRef.current.Placemark ( geometry, properties, options )
						);
					}

					return res;
				}, [] );

				if ( basePoints.length )
				{
					clusterer.current.add ( basePoints );

					return () => clusterer.current.remove ( basePoints );
				}
			}
		},
		// eslint-disable-next-line
		[ base ]
	);

	return (
		<MapWrapper>
			<Copyright />
			{ panel &&
				<Panel {...{ open }} />
			}
			<MapComponent {...{ getTemplates }}>
				<ZoomControl />
				{ !!templates &&
					<React.Fragment>
						<Clusterer
							instanceRef={ ref => {
								if ( ref ) ref.createCluster = getCreateCluster ( ymapsRef.current );

								clusterer.current = ref;
							} }
							defaultOptions={{
								...clustererOptions,
								clusterIconLayout: templates.clusterer,
								balloonLayout: templates.clusterBaloon,
								clusterBalloonItemContentLayout: templates.balloonContent
							}}
						>
							{ points.map ( data => getPlaceMark ( data, 'point', managersMap, templates, /*filters,*/ pointsRef ) ) }
						</Clusterer>
						{ position && ids.map ( managerID => {
							const data = managersMap[ managerID ] || {},
								[ lon, lat ] = data.position || [];

							if ( !lon || !lat ) return null;

							return (
								<Placemark
									key={ managerID }
									{ ...getPositionProps ( managerID, data, templates ) }
								/>
							);
						} ) }
						<MapMarginArea top={ 0 } right={ 0 } width={ 320 * ( !!panel + !!open ) } height="100%" panTo />
						<BoundsController {...{ map, points, ids, managersMap, preventAutoZoom, background, filters }} />
						<TablePointController {...{ clusterer, tablePoint, pointsRef, map, setTablePointControllerWaiter }} />
					</React.Fragment>
				}
			</MapComponent>
			<MapOptions>
				<ToggleButton value="position">
					<Icon icon="track" size={ 15 } />
				</ToggleButton>
			</MapOptions>
			<Waiter className={ classes.waiter } show={ waiter } />
		</MapWrapper>
	);
}


function getPointProps (
	{
		id,
		visitTime: visited = false,
		visitedTime,
		pdz = '-',
		sum = '-',
		togetherVisits = '-',
		tasks: { opened, closed } = {},
		coords: [ lat, lon ],
		color = 'green',
		managerID,
		name = '-',
		address = '-',
		nearby,
		photo
	},
	{ name: manager  = '-', initials  = '-' } = {},
	pointType,
	templates
)
{
	const isBase = pointType === 'base';

	opened = opened || 0;
	closed = closed || 0;

	return {
		geometry: [ lon, lat ],
		properties: {
			id,
			name,
			manager,
			managerID,
			address,
			clusterCaption: name,
			balloonContent: `<b>Менеджер:</b> ${ manager }<br /><b>Название:</b> ${ name }<br /><b>Адрес:</b> ${ address }<br /><b>ПДЗ:</b> ${ formatPrice ( pdz, ' ₽' ) }<br /><b>Сумма:</b> ${ formatPrice ( sum, ' ₽' ) }<br /><b>Задачи:</b> выполнено ${ closed } из ${ opened }<br /><b>Начало визита:</b> ${ ( visited && visited.split ( ' ' )[ 1 ] ) || '-' }<br /><b>Конец визита:</b> ${ ( visitedTime && visitedTime.split ( ' ' )[ 1 ] ) || '-' }<br />${ isBase ? 'Точка не входит в маршрут' : 'Точка входит в маршрут' }${ isBase ? '' : `<br />Точка ${ visited ? '' : 'не ' }посещена${ visited ? ( nearby ? '' : ' не в точке' ) : '' }` }`,
			visited: visited ? 'visited' : '',
			pdz: formatPrice ( pdz ),
			sum: formatPrice ( sum ),
			togetherVisits,
			opened,
			closed,
			initials,
			color: isBase ? '#A3A3A3' : getColorById ( managerID ),
			pointType,
			nearby: nearby ? 'nearby' : '',
			photo
		},
		options: {
			balloonLayout: templates.balloon,
			balloonContentLayout: templates.balloonContent,
			hideIconOnBalloonOpen: false,
			iconLayout: templates[ pointType ],
			iconShape: {
				type: 'Polygon',
				coordinates: [
					isBase ? [ [ 14, 0 ], [ 28, 10 ], [ 28, 22 ], [ 14, 32 ], [ 0, 22 ], [ 0, 10 ] ] :
						[ [ 0, 0 ], [ 14, 0 ], [ 14, -39 ], [ 104, -39 ], [ 104, 16 ], [ 28, 16 ], [ 28, 18 ], [ 14, 32 ], [ 0, 18 ] ]
				]
			},
			iconOffset: [ -14, -32 ],
			balloonPanelMaxMapArea: 0
		}
	};
}

function getPositionProps ( managerID, { position: [ lat, lon ], positionTime, name, initials }, templates )
{
	return {
		geometry: [ lon, lat ],
		properties: {
			balloonContent: `<b>Менеджер:</b> ${ name || '-' }<br /><b>Время:</b> ${ ( positionTime && positionTime.split ( ' ' )[ 1 ] ) || '-' }`,
			initials,
			color: getColorById ( managerID ),
			hideIconOnBalloonOpen: false
		},
		options: {
			iconLayout: templates.position,
			iconShape: {
				type: 'Polygon',
				coordinates: [
					[ [ 0, 0 ], [ 37, 0 ], [ 37, 17 ], [ 0, 17 ] ]
				]
			},
			iconOffset: [ -25, -22.5 ],
			balloonPanelMaxMapArea: 0
		}
	};
}

function getCreateCluster ( ymaps )
{
	return function createCluster ( center, geoObjects )
	{
		const clusterPlacemark = ymaps.Clusterer.prototype.createCluster.call ( this, center, geoObjects ),
			pdz = geoObjects.filter ( o => parseFloat ( o.properties.get ( 'pdz' ) ) ).length;

		clusterPlacemark.properties.set ( 'pdz', pdz );

		return clusterPlacemark;
	};
}

function getPlaceMark ( data, pointType, managersMap, templates, /*filters,*/ pointsRef )
{
	if ( !data.coords[ 0 ] && data.coords[ 0 ] !== 0 ) return null;
	if ( !data.coords[ 1 ] && data.coords[ 1 ] !== 0 ) return null;

	const key = `${ data.id }:${ data.managerID }:${ data.code }`;

	return (
		<Placemark
			{...{ key }}
			instanceRef={ ref => pointsRef.current[ key ] = ref }
			{ ...getPointProps ( data, managersMap[ data.managerID ], pointType, templates ) }
		/>
	);
}
