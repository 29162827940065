import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles ( theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'scroll'
    },
    merch: {
        backgroundColor: '#F8F8F8',
        padding: [[ 12, 26, 12, 16 ]],
        borderBottom: '1px solid #E3E3E3',
        display: 'flex',
        justifyContent: 'space-between'
    },
    merchHeader: {
        backgroundColor: '#FFFFFF',
        padding: [[ 10, 26, 10, 16 ]],
        borderBottom: '1px solid #E3E3E3',
        display: 'flex',
        justifyContent: 'space-between'
    },
    propName: {
        width: 'auto',
        maxWidth: 200,
        fontWeight: 'normal',
        fontSize: 14,
        paddingRight: 10
    },
    propValue: {
        width: 'auto',
        maxWidth: 100,
        fontWeight: 'normal',
        fontSize: 16
    },
    period: {
        width: 'auto',
        maxWidth: 100,
        fontWeight: 'bold',
        fontSize: 14
    },
    periodLabel: {
        width: 'auto',
        maxWidth: 100,
        fontWeight: 'normal',
        fontSize: 14
    }

}) );

export default function Merch ({period, client_id, row = []})
{
    const classes = useStyles();
	return (
        client_id ?
		<div className={ classes.root }>
            <div className={ classes.merchHeader  }>
                <div className={ classes.propName }>
                    Дата:
                </div>
                <div className={ classes.period }>
                    {period}
                </div>
            </div>
            {
                row.map(({propertyName, propertyValue, id}, i) => <div className={ classes.merch } key={id}>
                    <div className={ classes.propName }>
                        {propertyName}
                    </div>
                    <div className={ classes.propValue }>
                        {propertyValue}
                    </div>
                </div>)
            }
		</div> : ''
	);
}
