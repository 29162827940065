import { PhotoModal } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	({ panel }) => ({ photo: panel ? panel.photo : false, hidden: !panel }),
	dispatch => ({
		closePhotoModal: () => dispatch ( actions.togglePhotoModal ( false ) )
	})
)( PhotoModal );
