import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Pages } from 'containers';
import { SelectTableProvider } from 'components';


export default function App ()
{
	return (
		<SelectTableProvider>
			<CssBaseline />
			<Pages />
		</SelectTableProvider>
	);
}
