import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import MuiStepButton from '@material-ui/core/StepButton';
import MuiStepConnector from '@material-ui/core/StepConnector';
import MuiStepContent from '@material-ui/core/StepContent';
import MuiStepLabel from '@material-ui/core/StepLabel';
import { Photo } from 'components';
const Stepper = withStyles ({
		root: {
			padding: 18,
			backgroundColor: '#F8F8F8'
		}
	})( MuiStepper ),

	StepConnector = withStyles ( theme => ({
		root: {
			marginTop: -5,
			marginBottom: -5,
			marginLeft: 3,
			padding: 0
		},
		line: {
			borderColor: theme.palette.primary.main,
			minHeight: 0
		}
	}) )( MuiStepConnector ),

	StepContent = withStyles ( theme => ({
		root: {
			margin: [[ -5, 0, 0, 11 ]],
			padding: [[ 13, 0, 17, 11 ]],
			borderColor: theme.palette.primary.main
		}
	}) )( MuiStepContent ),

	StepButton = withStyles ({
		root: {
			padding: [[ 8, 16 ]]
		}
	})( MuiStepButton ),

	StepLabel = withStyles ( theme=> ({
		active: {
			color: [ theme.palette.primary.main, '!important' ]
		}
	}) )( MuiStepLabel ),

	useStyles = makeStyles ( theme => ({
		circle: {
			width: 7,
			height: 7,
			backgroundColor: theme.palette.primary.main,
			borderRadius: 3.5
		},
		photo: {
			margin: [[ 12, 0 ]]
		}
	}) );


export default function PhotoStepper ({ steps = [], active })
{
	const classes = useStyles(),
		[ activeStep, setActiveStep ] = useState ( null );

	useEffect (
		() => {
			if ( active )
			{
				if ( steps.length === 1 ) setActiveStep ( 0 );
			}
			else setActiveStep ( null );
		},
		[ steps, active ]
	);

	return (
		<Stepper
			nonLinear
			orientation="vertical"
			connector={ <StepConnector /> }
			{...{ activeStep }}
		>
			{ steps.map ( ( { brand, urls: [ photoId ] }, i ) =>
				<Step key={ `${ brand }-${ photoId }` }>
					<StepButton
						icon={ <div className={ classes.circle } /> }
						onClick={ () => setActiveStep ( i === activeStep ? null : i ) }
					>
						<StepLabel>{ brand }</StepLabel>
					</StepButton>
					<StepContent>
						<Photo className={ classes.photo } {...{ photoId }} />
					</StepContent>
				</Step>
			) }
		</Stepper>
	);
}
