import React, { useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { LogoutDialog, Calendar } from 'containers';
import { withStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import actions from 'actions';
const styles = {
	root: {
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
		height: 60
	},
	logo: {
		fontSize: 26,
		cursor: 'pointer'
	},
	left: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: 20
	},
	toolbar: {
		height: 60
	}
};


export default withStyles ( styles )( TopBar );

function TopBar ({ classes })
{
	const dispatch = useDispatch(),
		reset = useCallback (
			() => dispatch ( actions.reset() ),
			[ dispatch ]
		);

	return (
		<AppBar position="fixed" color="inherit" className={ classes.root }>
			<Toolbar className={ classes.toolbar }>
				<div className={ classes.left }>
					<Typography className={ classes.logo } color="primary" onClick={ reset }>
						MLK MAPS
					</Typography>

					<Calendar />
				</div>

				<LogoutDialog />
			</Toolbar>
		</AppBar>
	);
}
