import { all, debounce, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';
import { prepareFilters } from 'functions';


export default function* root ()
{
	yield all ([
		debounce ( 500, actionTypes.TABLE_REQUEST, tableRequest ),
		takeLatest ( actionTypes.TABLE_SET_DATE, updateTable )
	]);
}

export function* tableRequest ({ payload: { id, groupId, isGroup, ids, filterValues, tableType, date, closed, backButton, waiter } })
{
	const { table, managers } = yield select();

	if ( !table ) return;

	let data = { rows: [] },
		error;

	const filters = prepareFilters ( filterValues );

	if ( isGroup )
	{
		let res;

		if ( tableType === 'summary' )
		{
			const { from, to } = date || {};

			res = yield api.web.getGroupTableByPeriod ( groupId, from, to, ids, filters );
		}
		else res = yield api.web.getGroupTable ( groupId, date, ids, filters );

		data = res.group;
		error = res.error;
	}
	else if ( id !== undefined )
	{
		if ( !managers.ids.includes ( id ) )
		{
			yield put ( actions.selectManagers ( groupId, id ) );
		}

		let res;

		if ( tableType )
		{
			const { from, to } = date || {};

			if ( tableType === 'byDates' ) res = yield api.web.getTableByDates ( id, from, to, filters );
			else if ( tableType === 'byPoints' ) res = yield api.web.getTableByPoints ( id, from, to, filters );
		}
		else res = yield api.web.getTable ( id, date, filters );

		data = res.manager;
		error = res.error;
	}

	yield put (
		actions.table ({
			...data,
			id,
			groupId,
			backButton,
			isGroup,
			tableType,
			error,
			waiter,
			date,
			closed
		})
	);

	if ( error ) yield put ( actions.error ( error, true ) );
}

export function* updateTable ({ payload: { date, closed } })
{
	const { table } = yield select();

	if ( !table || !table.request ) return;

	const { tableType } = table.request,
		{ isRange } = table;

	// "isRange" requires "tableType" to be non-empty.
	if ( !tableType !== !isRange ) return;

	yield put (
		actions.tableRequest ({
			...table.request,
			date,
			closed: false
		})
	);
}
