import { createActions, handleActions, combineActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	name: '',
	phone: '',
	authorized: false,
	waiter: false
};


export const actions = createActions ({
	[ actionTypes.AUTH_REQUEST ]: phone => ({ phone }),
	[ actionTypes.AUTH ]: error => ({ error }),
	[ actionTypes.CONFIRM_REQUEST ]: code => ({ code }),
	[ actionTypes.CONFIRM ]: error => ({ error }),
	[ actionTypes.LOGIN ]: token => ({ token }),
	[ actionTypes.LOGOUT ]: null,
	[ actionTypes.SET_USER ]: user => ({ ...user }),
	[ actionTypes.RESET ]: null,
	[ actionTypes.ERROR ]: ( error, runAlert ) => ({ error, runAlert })
});

export default handleActions (
	{
		[ combineActions ( actions.authRequest, actions.confirmRequest ) ]: state => ({ ...state, waiter: true, error: false }),
		[ actionTypes.AUTH ]: ( state, { payload: { error } } ) => ({ ...state, error, confirm: !error, waiter: false }),
		[ actionTypes.CONFIRM ]: ( state, { payload: { error } } ) => ({ ...state, error, waiter: false }),
		[ actionTypes.LOGIN ]: () => ({ authorized: true }),
		[ actionTypes.LOGOUT ]: () => ({ authorized: false }),
		[ actionTypes.SET_USER ]: ( state, { payload } ) => ({ ...state, ...payload })
	},
	defaultState
);
