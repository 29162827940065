const evenodd = {
	fillRule: 'evenodd',
	clipRule: 'evenodd'
};

export default {
	chevronLeft: {
		d: 'M2.26456 6.36251L7.04846 11.1464L6.34136 11.8535L0.850351 6.36251L6.34136 0.871506L7.04846 1.57861L2.26456 6.36251Z',
		viewBox: '0 0 8 12'
	},
	chevronRight: {
		d: 'M5.7358 6.36259L0.951904 1.57869L1.65901 0.871582L7.15002 6.36259L1.65901 11.8536L0.951904 11.1465L5.7358 6.36259Z',
		viewBox: '0 0 8 12'
	},
	refresh: {
		d: [
			'M1.81662 6.68731C2.331 6.68731 2.79564 6.37846 2.99461 5.90397C3.81613 3.94665 5.74787 2.56723 7.99988 2.56723C9.24109 2.56723 10.379 2.99266 11.2913 3.69826L10.5762 3.80896C10.332 3.84682 10.142 4.0406 10.1089 4.28552C10.0757 4.5303 10.2077 4.76779 10.4329 4.86899L14.3652 6.63808C14.5376 6.71548 14.7373 6.7006 14.8961 6.59851C15.0547 6.49625 15.1511 6.32076 15.1522 6.13194L15.1725 1.7991C15.1739 1.55261 15.0132 1.33463 14.7777 1.26278C14.5417 1.19093 14.2868 1.28212 14.1503 1.4873L13.6083 2.30186C12.1633 0.879355 10.1832 0 7.99988 0C4.68945 0 1.84322 2.02162 0.629303 4.89494C0.460709 5.2932 0.503202 5.74924 0.741907 6.10954C0.980611 6.46998 1.3841 6.68731 1.81662 6.68731Z',
			'M15.2579 9.89051C15.0191 9.53004 14.6156 9.31274 14.1831 9.31274C13.6687 9.31274 13.2041 9.62159 13.0051 10.0961C12.1836 12.0534 10.2518 13.4328 7.99985 13.4328C6.75862 13.4328 5.62069 13.0074 4.70836 12.3018L5.42346 12.1911C5.66768 12.1533 5.85769 11.9595 5.89085 11.7145C5.92399 11.4698 5.79207 11.2323 5.56683 11.131L1.63453 9.36197C1.46213 9.2846 1.26247 9.29941 1.10356 9.40154C0.944982 9.5038 0.848601 9.67929 0.847583 9.86808L0.827157 14.2009C0.825778 14.4474 0.986425 14.6654 1.22201 14.7372C1.45796 14.8091 1.71288 14.7178 1.84936 14.5126L2.39136 13.6981C3.8364 15.1206 5.81654 16 7.99982 16C11.3103 16 14.1565 13.9784 15.3703 11.1051C15.539 10.7068 15.4965 10.2508 15.2579 9.89051Z'
		],
		viewBox: '0 0 16 16'
	},
	calendar: {
		d: [
			'M6.21744 8.77543C6.21744 8.58753 6.05487 8.4353 5.85428 8.4353H4.58697C4.3866 8.4353 4.224 8.58753 4.224 8.77543V9.96338C4.224 10.1515 4.3866 10.3038 4.58697 10.3038H5.85428C6.05487 10.3038 6.21744 10.1515 6.21744 9.96338V8.77543Z',
			'M9.3846 8.77543C9.3846 8.58753 9.22199 8.4353 9.02179 8.4353H7.75432C7.55396 8.4353 7.39136 8.58753 7.39136 8.77543V9.96338C7.39136 10.1515 7.55396 10.3038 7.75432 10.3038H9.02179C9.22199 10.3038 9.3846 10.1515 9.3846 9.96338V8.77543Z',
			'M12.5519 8.77543C12.5519 8.58753 12.3893 8.4353 12.1889 8.4353H10.9216C10.721 8.4353 10.5585 8.58753 10.5585 8.77543V9.96338C10.5585 10.1515 10.721 10.3038 10.9216 10.3038H12.1889C12.3893 10.3038 12.5519 10.1515 12.5519 9.96338V8.77543Z',
			'M6.21744 11.745C6.21744 11.5568 6.05487 11.4047 5.85428 11.4047H4.58697C4.3866 11.4047 4.224 11.5568 4.224 11.745V12.9327C4.224 13.1207 4.3866 13.273 4.58697 13.273H5.85428C6.05487 13.273 6.21744 13.1207 6.21744 12.9327V11.745Z',
			'M9.3846 11.745C9.3846 11.5568 9.22199 11.4047 9.02179 11.4047H7.75432C7.55396 11.4047 7.39136 11.5568 7.39136 11.745V12.9327C7.39136 13.1207 7.55396 13.273 7.75432 13.273H9.02179C9.22199 13.273 9.3846 13.1207 9.3846 12.9327V11.745Z',
			'M12.5519 11.745C12.5519 11.5568 12.3893 11.4047 12.1891 11.4047H10.9216C10.721 11.4047 10.5585 11.5568 10.5585 11.745V12.9327C10.5585 13.1207 10.721 13.273 10.9216 13.273H12.1891C12.3893 13.273 12.5519 13.1207 12.5519 12.9327V11.745Z',
			'M14.818 2.17021V3.98471C14.818 4.80484 14.1083 5.46574 13.2337 5.46574H12.2342C11.3595 5.46574 10.6404 4.80484 10.6404 3.98471V2.1637H6.13554V3.98471C6.13554 4.80484 5.41648 5.46574 4.54187 5.46574H3.54222C2.66757 5.46574 1.95792 4.80484 1.95792 3.98471V2.17021C1.19347 2.19181 0.565308 2.78483 0.565308 3.51374V14.1475C0.565308 14.8902 1.20734 15.5 1.99945 15.5H14.7765C15.5674 15.5 16.2106 14.8889 16.2106 14.1475V3.51374C16.2106 2.78483 15.5825 2.19181 14.818 2.17021ZM14.3538 13.4854C14.3538 13.8063 14.0762 14.0666 13.7338 14.0666H3.01481C2.67236 14.0666 2.3948 13.8063 2.3948 13.4854V7.99261C2.3948 7.67156 2.67232 7.4112 3.01481 7.4112H13.7338C14.0762 7.4112 14.3538 7.67156 14.3538 7.99261L14.3538 13.4854Z',
			'M3.53873 4.49297H4.52734C4.82741 4.49297 5.07071 4.26521 5.07071 3.98389V1.00926C5.07071 0.727911 4.82741 0.5 4.52734 0.5H3.53873C3.23863 0.5 2.99536 0.727911 2.99536 1.00926V3.98389C2.99536 4.26521 3.23863 4.49297 3.53873 4.49297Z',
			'M12.2216 4.49297H13.2102C13.51 4.49297 13.7533 4.26521 13.7533 3.98389V1.00926C13.7534 0.727911 13.5101 0.5 13.2102 0.5H12.2216C11.9215 0.5 11.6782 0.727911 11.6782 1.00926V3.98389C11.6782 4.26521 11.9215 4.49297 12.2216 4.49297Z'
		],
		viewBox: '0 0 17 16'
	},
	user: {
		d: 'M20.6627 22.884C18.7325 24.4119 16.2927 25.3241 13.6399 25.3241C10.987 25.3241 8.54719 24.4119 6.61701 22.884C7.93606 20.3504 10.5859 18.6203 13.6398 18.6203C16.6938 18.6203 19.3436 20.3504 20.6627 22.884ZM22.1109 21.5153C20.8722 19.4284 18.8843 17.8385 16.5178 17.1161C18.1829 16.1309 19.2996 14.3167 19.2996 12.2417C19.2996 9.11593 16.7656 6.58198 13.6398 6.58198C10.514 6.58198 7.98009 9.11593 7.98009 12.2417C7.98009 14.3167 9.09671 16.1309 10.7618 17.1161C8.39541 17.8385 6.4075 19.4284 5.16876 21.5152C3.39377 19.5159 2.3158 16.8838 2.3158 14C2.3158 7.74588 7.38577 2.67591 13.6399 2.67591C19.894 2.67591 24.964 7.74588 24.964 14C24.964 16.8839 23.886 19.516 22.1109 21.5153ZM26.8955 14C26.8955 21.3209 20.9608 27.2556 13.6399 27.2556C6.31902 27.2556 0.384277 21.3209 0.384277 14C0.384277 6.67913 6.31902 0.744385 13.6399 0.744385C20.9608 0.744385 26.8955 6.67913 26.8955 14ZM13.6398 15.9699C15.6989 15.9699 17.368 14.3007 17.368 12.2417C17.368 10.1827 15.6989 8.5135 13.6398 8.5135C11.5808 8.5135 9.91161 10.1827 9.91161 12.2417C9.91161 14.3007 11.5808 15.9699 13.6398 15.9699Z',
		viewBox: '0 0 28 28',
		...evenodd
	},
	filters: {
		d: [
			'M2.5 8V0H3.5V8H2.5Z',
			'M2.5 17V14H3.5V17H2.5Z',
			'M3 13C4.10457 13 5 12.1046 5 11C5 9.89543 4.10457 9 3 9C1.89543 9 1 9.89543 1 11C1 12.1046 1.89543 13 3 13ZM3 14C4.65685 14 6 12.6569 6 11C6 9.34315 4.65685 8 3 8C1.34315 8 0 9.34315 0 11C0 12.6569 1.34315 14 3 14Z',
			'M14.5 8V0H15.5V8H14.5Z',
			'M14.5 17V14H15.5V17H14.5Z',
			'M15 13C16.1046 13 17 12.1046 17 11C17 9.89543 16.1046 9 15 9C13.8954 9 13 9.89543 13 11C13 12.1046 13.8954 13 15 13ZM15 14C16.6569 14 18 12.6569 18 11C18 9.34315 16.6569 8 15 8C13.3431 8 12 9.34315 12 11C12 12.6569 13.3431 14 15 14Z',
			'M9.5 9.16745L9.5 17.1674L8.5 17.1674L8.5 9.16745L9.5 9.16745Z',
			'M9.50024 0.167419L9.50024 3.16742L8.50024 3.16742L8.50024 0.167419L9.50024 0.167419Z',
			'M9 4.16745C7.89543 4.16745 7 5.06288 7 6.16745C7 7.27202 7.89543 8.16745 9 8.16745C10.1046 8.16745 11 7.27202 11 6.16745C11 5.06288 10.1046 4.16745 9 4.16745ZM9 3.16745C7.34315 3.16745 6 4.5106 6 6.16745C6 7.8243 7.34315 9.16745 9 9.16745C10.6569 9.16745 12 7.8243 12 6.16745C12 4.5106 10.6569 3.16745 9 3.16745Z'
		],
		viewBox: '0 0 18 18',
		...evenodd
	},
	close: {
		viewBox: '0 0 13 13',
		d: [
			'M12.1465 12.8536L0.646484 1.35359L1.35359 0.646484L12.8536 12.1465L12.1465 12.8536Z',
			'M0.64653 12.1465L12.1465 0.646484L12.8536 1.35359L1.35364 12.8536L0.64653 12.1465Z'
		]
	},
	download: {
		viewBox: '0 0 19 19',
		d: [
			'M18.4556 9.28035C18.1532 9.28035 17.9113 9.52229 17.9113 9.82471V14.7763C17.9113 16.1231 16.8145 17.2158 15.4718 17.2158H3.52823C2.18145 17.2158 1.08871 16.1191 1.08871 14.7763V9.74406C1.08871 9.44164 0.846774 9.19971 0.544355 9.19971C0.241935 9.19971 0 9.44164 0 9.74406V14.7763C0 16.7239 1.58468 18.3045 3.52823 18.3045H15.4718C17.4194 18.3045 19 16.7199 19 14.7763V9.82471C19 9.52632 18.7581 9.28035 18.4556 9.28035Z',
			'M9.11691 13.9821C9.22175 14.0869 9.36288 14.1434 9.49998 14.1434C9.63707 14.1434 9.7782 14.091 9.88304 13.9821L13.3427 10.5224C13.5564 10.3087 13.5564 9.96596 13.3427 9.75225C13.129 9.53854 12.7863 9.53854 12.5726 9.75225L10.0443 12.2845V1.24016C10.0443 0.937736 9.8024 0.695801 9.49998 0.695801C9.19756 0.695801 8.95562 0.937736 8.95562 1.24016V12.2845L6.42336 9.75225C6.20965 9.53854 5.86691 9.53854 5.6532 9.75225C5.43949 9.96596 5.43949 10.3087 5.6532 10.5224L9.11691 13.9821Z'
		]
	},
	miniCheck: {
		d: 'M14.7222 8.5H9.27778C8.85 8.5 8.5 8.85 8.5 9.27778V14.7222C8.5 15.15 8.85 15.5 9.27778 15.5H14.7222C15.15 15.5 15.5 15.15 15.5 14.7222V9.27778C15.5 8.85 15.15 8.5 14.7222 8.5Z'
	},
	search: {
		d: 'M16.4309 10.7154C16.4309 13.872 13.872 16.4309 10.7154 16.4309C7.55889 16.4309 5 13.872 5 10.7154C5 7.55889 7.55889 5 10.7154 5C13.872 5 16.4309 7.55889 16.4309 10.7154ZM15.2977 16.9234C14.0163 17.8708 12.4313 18.4309 10.7154 18.4309C6.45432 18.4309 3 14.9766 3 10.7154C3 6.45432 6.45432 3 10.7154 3C14.9766 3 18.4309 6.45432 18.4309 10.7154C18.4309 12.5888 17.7632 14.3062 16.6529 15.6427L21.5881 20.5778L20.2459 21.92L15.2735 16.9476L15.2977 16.9234Z',
		...evenodd
	},
	cross: {
		d: [
			'M16.6464 17.3536L6.64645 7.35355L7.35355 6.64645L17.3536 16.6464L16.6464 17.3536Z',
			'M6.64645 16.6464L16.6464 6.64645L17.3536 7.35355L7.35355 17.3536L6.64645 16.6464Z'
		],
		...evenodd
	},
	chevronUp: {
		d: 'M2 15.7235L4.34124 18L12 10.553L19.6588 18L22 15.7235L12 6L2 15.7235Z',
		...evenodd
	},
	chevronDown: {
		d: 'M2 8.27651L4.34124 6L12 13.447L19.6588 6L22 8.27651L12 18L2 8.27651Z',
		...evenodd
	},
	camera: {
		d: [
			'M18.9143 8.08603C18.5236 7.69544 18.0524 7.50009 17.5002 7.50009H15.7502L15.3519 6.43765C15.2529 6.18248 15.072 5.9624 14.8088 5.77741C14.5457 5.59252 14.2762 5.5 14.0002 5.5H10.0001C9.72401 5.5 9.45445 5.59252 9.1914 5.77741C8.92835 5.9624 8.74742 6.18248 8.64844 6.43765L8.25002 7.50009H6.50001C5.94783 7.50009 5.47657 7.69544 5.08589 8.08603C4.6953 8.47666 4.5 8.94797 4.5 9.5001V16.5001C4.5 17.0523 4.6953 17.5237 5.08589 17.9142C5.47657 18.3049 5.94786 18.5002 6.50001 18.5002H17.5C18.0522 18.5002 18.5234 18.3049 18.9141 17.9142C19.3046 17.5237 19.5 17.0523 19.5 16.5001V9.5001C19.5001 8.94797 19.3048 8.47666 18.9143 8.08603ZM14.4727 15.4729C13.7878 16.1578 12.9637 16.5003 12 16.5003C11.0364 16.5003 10.2123 16.1578 9.52737 15.4729C8.84242 14.7881 8.50002 13.9637 8.50002 13.0003C8.50002 12.0366 8.84253 11.2126 9.52737 10.5276C10.2122 9.84267 11.0365 9.50026 12 9.50026C12.9636 9.50026 13.7878 9.84275 14.4727 10.5276C15.1576 11.2125 15.5001 12.0366 15.5001 13.0003C15.5001 13.9637 15.1577 14.788 14.4727 15.4729Z',
			'M12.0001 10.75C11.3803 10.75 10.8503 10.9701 10.4102 11.4102C9.97006 11.8504 9.75 12.3802 9.75 13.0002C9.75 13.6199 9.97006 14.1499 10.4102 14.59C10.8503 15.03 11.3802 15.2501 12.0001 15.2501C12.6198 15.2501 13.1498 15.03 13.59 14.59C14.0301 14.1499 14.2502 13.6199 14.2502 13.0002C14.2502 12.3803 14.0301 11.8504 13.59 11.4102C13.1499 10.9701 12.6198 10.75 12.0001 10.75Z'
		]
	},
	arrow: {
		d: 'M19.1576 11L15.4929 6.37068L17.061 5.12932L22.5 12L17.061 18.8707L15.4929 17.6293L19.1576 13L1.22459 13L1.22459 11L19.1576 11Z',
		...evenodd
	},
	backArrow: {
		d: 'M4.56705 13L8.23172 17.6293L6.6636 18.8707L1.22461 12L6.6636 5.12932L8.23172 6.37068L4.56705 11H22.5V13H4.56705Z',
		...evenodd
	}
};
