import { createActions, handleActions, combineActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = false;


export const actions = createActions ({
	[ actionTypes.TABLE_REQUEST ]: ({ id, groupId, isGroup, ids, selected, filterValues, tableType, date, backButton, closed }) =>
		({ id, groupId, isGroup, ids, selected, filterValues, tableType, date, backButton, waiter: + new Date(), closed }),
	[ actionTypes.TABLE ]: data => data,
	[ actionTypes.TABLE_CLOSE ]: data => data,
	[ actionTypes.SET_TABLE_POINT_CONTROLLER_WAITER ]: status => status,
	[ actionTypes.TABLE_SET_DATE ]: (date, closed) => ({ date, closed })
});

export default handleActions (
	{
		[ combineActions ( actionTypes.LOGOUT, actionTypes.RESET ) ]: () => defaultState,
		[ actionTypes.TABLE_REQUEST ]: ( state, action ) => updateIsRange ( tableRequestReducer ( state, action ) ),
		[ actionTypes.TABLE ]: tableReducer,
		[ actionTypes.TABLE_CLOSE ]: tableCloseReducer,
		[ actionTypes.SET_TABLE_POINT_CONTROLLER_WAITER ]: ( state, { payload: tablePointControllerWaiter } ) => state && ({ ...state, tablePointControllerWaiter }),
		[ actionTypes.TABLE_SET_DATE ]: ( state, action ) => updateIsRange ( tableSetDateReducer ( state, action ) ),
		[ actionTypes.POINTS_SET_DATE ]: ( state, { payload: { closeTable } } ) => closeTable ? false : state,
		[ combineActions ( actionTypes.SET_FILTERS, actionTypes.RESET_FILTERS, actionTypes.SELECT_MANAGERS ) ]: highlightTableTrigger
	},
	defaultState
);


function tableRequestReducer ( state, { payload } )
{
	const { waiter, ...request } = payload;
	const { closed } = payload;

	if ( state.isGroup !== payload.isGroup || state.tableType !== payload.tableType )
	{
		return { ...payload, request, closed: closed, prevStateByPeriod: state.tableType};
	}

	return { ...state, ...payload, request, highlight: false, closed: false};
}

function tableReducer ( state, { payload } )
{
	const { closed, isRange, date, highlight, request, prevStateByPeriod } = state;
	if ( !payload) {
		return { 
			...defaultState,
			date,
			isRange,
			// isGroup,
			closed: true,
			prevStateByPeriod
		};
	}
	else if ( payload.waiter !== state.waiter ) return { ...state, closed: closed, prevStateByPeriod };

	return updateIsRange ({ ...payload, isRange, highlight, request, closed: closed, waiter: false, prevStateByPeriod });
}

function tableCloseReducer ( state )
{
	const { isRange, date, prevStateByPeriod } = state;

	return { 
		...defaultState,
		date,
		isRange,
		// isGroup,
		closed: true,
		prevStateByPeriod
	};
}

function tableSetDateReducer ( state, { payload: { date, closed } } )
{
	if (!closed) {
		return ({ ...state, date, closed: state.closed });
	}
	return ({ ...state, date, closed });
}

function updateIsRange ( state )
{
	const { date, closed } = state,
		isRange = typeof date === 'object' && !( date instanceof Date );

	if ( !!state.isRange === !!isRange ) {
		return state;
	}

	// Reset table data if isRange was changed.
	return [ 'id', 'groupId', 'date', 'tableType', 'backButton', 'waiter', 'request' ].reduce (
		( res, prop ) => ({ ...res, [ prop ]: state[ prop ] }),
		{ isRange, closed }
	);
}

function highlightTableTrigger ( state, action )
{
	const { closed } = state;
	if ( !state || closed ) return state;

	const { isGroup, groupId, managerId } = state,
		highlight = { isGroup, groupId, managerId };

	if ( action.type === actionTypes.SELECT_MANAGERS )
	{
		const { groupId, all, excludeBase } = action.payload;

		if ( excludeBase !== undefined ) return state;

		if ( all !== undefined || ( isGroup && state.groupId === groupId ) )
		{
			return { ...state, highlight };
		}

		return state;
	}

	return { ...state, highlight };
}
