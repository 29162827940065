import { all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';


export default function* root ()
{
	yield all ([
		takeLatest ( actionTypes.GET_GROUPS, getGroups ),
		takeLatest ( actionTypes.RESET, reset ),
	]);
}

export function* getGroups ({ payload: { filter } })
{
	const { groups = [], count = 0, user = {}, error } = yield api.web.getManagers ( filter ),
		{ result, selected } = processGroups ( groups );

	yield put ( actions.setUser ( user ) );
	yield put ( actions.setGroups ( result, count, selected, error ) );

	if ( error ) yield put ( actions.error ( error ) );
}


function processGroups ( groups = [] )
{
	const selected = {},
		result = groups.map ( ({ managers = [], id: groupId, ...group }) => {
			selected[ groupId ] = managers.reduce ( ( res, { id } ) => ({ ...res, [ id ]: false }), { all: false, incomplete: false } );

			return { ...group, id: groupId, managers };
		} );

	return { result, selected };
}

function* reset ()
{
	yield put ( actions.selectManagers ( false, false, false ) );
}
