import React, { useRef, useState, useCallback } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import { FigmaIcon } from 'components';
const useStyles = makeStyles ({
	root: {
		display: 'inline-flex',
		flexDirection: 'column',
		position: 'relative'
	},
	input: ({ htmlColor, textColor, borderRadius, inputPaddingLeft, withIcon }) => ({
		boxSizing: 'border-box',
		margin: 0,
		'& label.Mui-focused, label': {
			color: textColor || htmlColor
		},
		'& label.MuiInputLabel-shrink': {
			transform: `translate(${ borderRadius + 6 }px, -6px) scale(0.75)`
		},
		'& label': {
			transform: `translate(${ inputPaddingLeft }px, 20px) scale(1)`
		},
		'& input': {
			color: textColor || htmlColor,
			borderRadius,
			overflow: 'hidden',
			paddingLeft: inputPaddingLeft,
			paddingRight: withIcon ? 36 : 14
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: htmlColor,
				borderRadius,
				paddingLeft: [ [ borderRadius ], '!important' ],
				paddingRight: [ [ borderRadius ], '!important' ],
				transition: 'margin-top 0ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
				marginTop: 0
			},
			'&:hover fieldset': {
				borderColor: htmlColor
			},
			'&.Mui-focused fieldset': {
				borderColor: htmlColor,
				marginTop: 1
			}
		}
	}),
	iconButton: {
		position: 'absolute',
		right: 6,
		top: 5.5
	},
	icon: {
		color: '#E0E0E0'
	}
});


export default React.memo ( Input );

function Input ({ className, htmlColor = '#FFF', textColor, borderRadius = 28, inputPaddingLeft = 20, mask, variant = 'outlined', withIcon, value, onChange, ...other })
{
	const classes = useStyles ({ htmlColor, textColor, borderRadius, inputPaddingLeft, withIcon }),
		inputRef = useRef ({}),
		[ showMask, setShowMask ] = useState ( false ),
		[ isFocused, setIsFocused ] = useState ( false ),
		onFocus = useCallback (
			() => {
				setTimeout ( () => setShowMask ( true ) );

				if ( withIcon ) setIsFocused ( true );
			},
			[ setShowMask, withIcon ]
		),
		notEmpty = !!value,
		onBlur = useCallback (
			() => {
				if ( !notEmpty ) setShowMask ( false );

				if ( withIcon ) setIsFocused ( false );
			},
			[ setShowMask, notEmpty, withIcon ]
		),
		clear = useCallback (
			() => {
				onChange ({ target: { value: '' } });

				if ( inputRef.current.focus ) inputRef.current.focus();
			},
			[ onChange, inputRef ]
		);

	if ( mask )
	{
		other.InputProps = { ...other.InputProps, inputComponent: TextMaskCustom };
		other.inputProps = { ...other.inputProps, mask, showMask };
	}

	return (
		<div className={ clsx ( classes.root, className ) }>
			<TextField
				className={ classes.input }
				{...{ inputRef, variant, onFocus, onBlur, onChange, value }}
				{ ...other }
			/>
			{ withIcon && ( !!value || !isFocused ) &&
				<IconButton
					className={ classes.iconButton }
					size="small"
					disabled={ !value }
					onClick={ clear }
				>
					<FigmaIcon className={ classes.icon } name={ value ? 'cross' : 'search' } />
				</IconButton>
			}
		</div>
	);
}


function TextMaskCustom ({ inputRef, ...other })
{
	const ref = useCallback (
		ref => {
			inputRef ( ref ? ref.inputElement : null );
		},
		[ inputRef ]
	);

	return (
		<MaskedInput
			{...{ ref }}
			placeholderChar="_"
			guide={ false }
			{ ...other }
		/>
	);
}
