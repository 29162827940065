import { createActions, handleActions, combineActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = false;


export const actions = createActions ({
	[ actionTypes.OPEN_PANEL ]: data => data,
	[ actionTypes.CLOSE_PANEL ]: null,
	[ actionTypes.TOGGLE_PHOTO_MODAL ]: photo => ({ photo })
});

export default handleActions (
	{
		[ combineActions ( actionTypes.LOGOUT, actionTypes.RESET ) ]: () => defaultState,
		[ actionTypes.OPEN_PANEL ]: ( state, { payload } ) => payload,
		[ actionTypes.CLOSE_PANEL ]: () => false,
		[ combineActions ( actionTypes.POINTS, actionTypes.POINTS_BACKGROUND ) ]: refreshPanel,
		[ actionTypes.TOGGLE_PHOTO_MODAL ]: ( state, { payload: { photo } } ) => ({ ...state, photo }),
		[ actionTypes.TABLE ]: ( state, { payload } ) => payload ? defaultState : state
	},
	defaultState
);


function refreshPanel ( state, { payload: { points, managersMap } } )
{
	if ( !state || !points ) return state;

	const { id, managerID } = state;

	points = points.filter ( point => point.id === id );

	if ( !points.length ) return state;

	let point = points.find ( point => point.managerID = managerID ),
		newState = state;

	if ( point )
	{
		newState = { ...newState, ...point.tasks };
	}
	else point = points[ 0 ];

	return {
		...newState,
		manager: managersMap[ managerID ].name,
		name: point.name,
		address: point.address
	};
}
