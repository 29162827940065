import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles, withStyles } from '@material-ui/core/styles';
const toggles = [
		'togetherVisits',
		'pdz',
		'sum',
		'photo'
	],
	labels = [
		'СВ',
		'ПДЗ',
		'Сумма',
		'Фото'
	],
	useStyles = makeStyles ({
		container: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: 20
		},
		label: {
			margin: [[ 3, 16 ]]
		}
	});


export default React.memo ( Toggle );

function Toggle ({ values, setFilters })
{
	const classes = useStyles();

	return (
		<div className={ classes.container }>
			{ toggles.map ( ( name, i ) => (
				<FormControlLabel
					key={ name }
					className={ classes.label }
					control={
						<ToggleSwitch
							value={ name }
							onChange={ ({ target: { checked } }) => setFilters ({ [ name ]: checked }) }
							checked={ values[ name ] || false }
						/>
					}
					label={ labels[ i ] }
				/>
			) ) }
		</div>
	);
}

const ToggleSwitch = withStyles ( theme => ({
	root: {
		width: 36,
		height: 18,
		padding: 0,
		display: 'flex',
		marginRight: 12
	},
	switchBase: {
		padding: 3,
		color: theme.palette.primary.main,
		'&$checked': {
			transform: 'translateX(18px)',
			color: theme.palette.common.white,
			'& + $track': {
				opacity: 1,
				backgroundColor: theme.palette.primary.main,
				borderColor: theme.palette.primary.main
			}
		}
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: 'none'
	},
	track: {
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: 10,
		opacity: 1,
		backgroundColor: theme.palette.common.white
	},
	checked: {}
}) )( Switch );
