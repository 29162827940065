import { createActions, handleActions, combineActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	points: [],
	base: [],
	date: new Date(),
	range: [],
	preventAutoZoom: false,
	waiter: false,
	background: false,
	filters: {
		position: true,
		// visited: true,
		// notVisited: true
	},
	disabledFilters: {},
	tablePoint: false // Открытый из таблицы балун
};


export const actions = createActions ({
	[ actionTypes.POINTS_REQUEST ]: waiter => ({ waiter }),
	[ actionTypes.POINTS ]: ( points, base, managersMap, waiter ) => ({ points, base, managersMap, waiter }),
	[ actionTypes.REQUEST_POINTS_BACKGROUND ]: () => ({ background: true }),
	[ actionTypes.POINTS_BACKGROUND ]: ( points, managersMap ) => ({ points, managersMap }),
	[ actionTypes.SET_BACKGROUND ]: background => ({ background }),
	[ actionTypes.POINTS_SET_DATE ]: ( date, range, closeTable ) => ({ date, range, closeTable }),
	[ actionTypes.SET_TABLE_POINT ]: payload => payload // { id, managerId } | false
});

export default handleActions (
	{
		[ combineActions ( actionTypes.LOGOUT, actionTypes.RESET ) ]: () => defaultState,
		[ actionTypes.POINTS_REQUEST ]: ( state, { payload: { waiter } } ) => ({ ...state, waiter }),
		[ actionTypes.POINTS ]: ( state, { payload: { points, base, waiter } } ) => waiter === state.waiter ? { ...state, points, base, waiter: false } : state,
		[ actionTypes.POINTS_BACKGROUND ]: ( state, { payload: { points } } ) => ({ ...state, points, background: true }),
		[ actionTypes.SET_BACKGROUND ]: ( state, { payload } ) => ({ ...state, ...payload }),
		[ actionTypes.POINTS_SET_DATE ]: ( state, { payload: { date, range } } ) => ({ ...state, date, range }),
		[ actionTypes.SET_GROUPS ]: state => ({ ...state, points: [] }),
		[ actionTypes.SELECT_MANAGERS ]: ( state, { payload: { preventAutoZoom } } ) => ({ ...state, preventAutoZoom }),
		[ actionTypes.SET_TABLE_POINT ]: ( state, { payload: tablePoint } ) => ({ ...state, tablePoint })
	},
	defaultState
);
