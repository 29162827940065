import React, { useRef, useEffect } from 'react';


export default React.memo ( ScrollIntoView );

function ScrollIntoView ({ children })
{
	const ref = useRef ( null );

	useEffect (
		() => {
			const { current } = ref;

			if ( current && current.scrollIntoView )
			{
				current.scrollIntoView ({ behavior: 'smooth' });
			}
		},
		[]
	);

	return (
		<React.Fragment>
			<div {...{ ref }} />
			{ children }
		</React.Fragment>
	);
}
