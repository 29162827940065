import React, { useState, useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Waiter, Input } from 'components';
import { PointFilters } from 'containers';
import NoManagersDialog from './NoManagersDialog';
import Group from './Group';
const useStyles = makeStyles ({
		root: {
			overflowY: 'scroll',
			flex: 1
		},
		all: {
			display: 'flex',
			alignItems: 'stretch',
			flexDirection: 'column'
		},
		allManagers: {
			padding: [[ 4, 16, 12, 16 ]],
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		allManagersLabel: {
			margin: [[ 0, 0, 0, -7 ]]
		},
		input: {
			margin: [[ 31, 50, 8, 24 ]]
		},
		label: {
			display: 'flex',
			alignItems: 'center'
		},
		footer: {
			height: 57,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: [[ 0, 24 ]]
		},
		error: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: 40
		},
		refreshButton: {
			color: '#FFF',
			marginTop: 20
		}
	});


export default function Managers ({
	tableType,
	isRange,
	groups = [],
	count,
	selected,
	managersMap,
	all,
	ids,
	includeBase,
	error,
	waiter,
	disabled,
	selectManagers,
	logout,
	getGroups
})
{
	const classes = useStyles(),
		[ list, setList ] = useState ( groups ),
		[ expanded, setExpanded ] = useState ( false ),
		[ filter, setFilter ] = useState ( '' ),
		handleChange = useCallback (
			panel => ( event, newExpanded ) => {
				setExpanded ( newExpanded ? panel : false );

				if ( newExpanded )
				{
					const { currentTarget } = event;

					setTimeout (
						() => currentTarget.scrollIntoView ({ behavior: 'smooth' }),
						0
					);
				}
			},
			[ setExpanded ]
		),
		[ filterGroupsDebounced ] = useDebouncedCallback (
			( groups, filter ) => setList (
				filterGroups ( groups, filter )
			),
			300
		);

	useEffect (
		() => {
			if ( groups.length ) filterGroupsDebounced ( groups, filter );
		},
		// eslint-disable-next-line
		[ filter ]
	);

	useEffect (
		() => setList ( groups ),
		[ groups ]
	);

	return (
		<React.Fragment>
			<div className={ classes.root }>
				<div className={ classes.all }>
					<Input
						className={ classes.input }
						label='Найти менеджера'
						htmlColor="#EDEDED"
						textColor="#959595"
						onChange={ e => setFilter ( e.target.value ) }
						value={ filter }
						margin="dense"
						borderRadius={ 20 }
						inputPaddingLeft={ 14 }
						withIcon
					/>

					<div className={ classes.allManagers }>
						<FormControlLabel
							className={ classes.allManagersLabel }
							control={
								<Checkbox
									color="primary"
									onChange={ () => selectManagers ( false, false, !all ) }
									checked={ !!all }
								/>
							}
							label={
								<div className={ classes.label }>
									Отображать всех менеджеров
								</div>
							}
						/>
						<PointFilters />
					</div>
				</div>

				{ list.map ( ({ id: groupId, name, managers }) =>
					<Group
						key={ groupId }
						expanded={ expanded === groupId || list.length === 1 }
						selected={ selected[ groupId ] }
						showIncludeBase={ ids.length === 1 }
						{...{ isRange, tableType, groupId, name, managers, managersMap, includeBase, handleChange, selectManagers }}
					/>
				) }

				<Waiter show={ waiter } />

				<NoManagersDialog open={ !( waiter || error || groups.length ) } {...{ logout }} />

				{ !!error &&
					<div className={ classes.error }>
						<Typography>{ error }</Typography>
						<Button
							className={ classes.refreshButton }
							variant="contained"
							color="primary"
							onClick={ getGroups }
						>
							Обновить
						</Button>
					</div>
				}
			</div>
			<div className={ classes.footer }>
				<Typography>Всего менеджеров: <b>{ count || 0 }</b></Typography>
			</div>
		</React.Fragment>
	);
}


function filterGroups ( groups, filter )
{
	if ( !filter ) return groups;

	const re = new RegExp ( filter, 'i' );

	return groups.reduce ( ( groups, { managers, ...group } ) => {
		group.managers = managers.filter ( ({ name, id }) => id === 'all' || re.test ( name ) );

		if ( group.managers.length ) groups.push ( group );

		return groups;
	}, [] );
}
