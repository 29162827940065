import { Filters } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';

const mapStateToProps = ({ filters: { waiter, idicator, ...filters } }) => filters,
	mapDispatchToProps = dispatch => ({
		initFiltersRequest: () => dispatch ( actions.initFiltersRequest() ),
		setFilters: filters => dispatch ( actions.setFilters ( filters ) ),
		resetFilters: () => dispatch ( actions.resetFilters() )
	});

export default Object.keys ( Filters )
	.reduce (
		( res, name ) => ({
			...res,
			[ name ]: connect ( mapStateToProps, mapDispatchToProps )( Filters[ name ] )
		}),
		{}
	);
