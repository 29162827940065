import React, {useMemo} from 'react';
import {Button, Dialog, DialogActions, DialogContent, makeStyles} from '@material-ui/core';
import {FigmaIcon} from 'components';
import {SideButton} from 'mlk-ui-kit';
import {saveAs} from 'file-saver';
import Bowser from 'bowser';

const {browser, platform} = Bowser.parse(window.navigator.userAgent),
    isLink = browser.name === 'Safari' && platform.type !== 'desktop',
    useStyles = makeStyles({
        backdrop: {
            backgroundColor: ['rgba(0, 0, 0, 0.9)', '!important']
        },
        root: {
            "& .MuiPaper-root": {
                backgroundColor: ['transparent', '!important'],
                boxShadow: ['none', '!important'],
                borderRadius: 0,
                overflowY: 'inherit',
                margin: 0
            }
        },
        content: {
            padding: ['0 0 0 0', '!important'],
            overflow: 'hidden',
            position: "relative"
        },
        close: {
            position: 'absolute',
            right: -12,
            top: -12,
            zIndex: 1400
        },
        img: {
            maxWidth: '100%'
        },
        actions: {
            backgroundColor: ['transparent', '!important'],
            boxShadow: ['none', '!important'],
            margin: "0 auto"
        },
        download: {
            marginTop: 40,
            height: 56,
            width: 210,
            borderRadius: 28,
            border: '1px solid #FFF',
            color: '#FFF',
            fontSize: 14,
            fontWeight: 'bold'
        },
        icon: {
            fontSize: 18,
            marginRight: 8
        }
    });


export default React.memo(PhotoModal);

function PhotoModal({photo, hidden, closePhotoModal}) {
    const classes = useStyles(),
        buttonProps = useMemo(
            () => isLink ? {href: photo, target: '_blank'} : {onClick: () => saveAs(photo)},
            [photo]
        ),
        BackdropProps = useMemo(
            () => ({className: classes.backdrop}),
            [classes.backdrop]
        );

    if (hidden) return null;

    return (
        <Dialog open={!!photo} className={classes.root} onClose={closePhotoModal}
                onBackdropClick={closePhotoModal} {...{BackdropProps}}>
            <SideButton className={classes.close} onClick={closePhotoModal}/>
            <DialogContent className={classes.content}>
                <img className={classes.img} src={photo} alt=""/>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    className={classes.download}
                    {...buttonProps}
                >
                    <FigmaIcon className={classes.icon} name="download"/>
                    Скачать фото
                </Button>
            </DialogActions>
        </Dialog>
    );
}
