import React, { useContext, useState, useCallback } from 'react';
import { RangeSwitch as MukRangeSwitch } from 'mlk-ui-kit';
import { DatePicker } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { useRequestTableData } from 'hooks';
import { SelectTable } from 'context';
import actions from 'actions';
import moment from 'moment';

const today = moment().add(7, 'days').endOf ( 'day' ).toDate(); // TODO sync with Calendar


export default React.memo ( RangeSwitch );

function RangeSwitch ()
{
	const dispatch = useDispatch(),
		openSelectTableDialog = useContext ( SelectTable ),
		table = useSelector ( st => st.table ),
		isTable = !!table,
		isRange = !!( table && table.isRange ),
		{ isGroup, request } = table || {},
		requestData = useRequestTableData ( isGroup ),
		[ datePickerIsRange, setDatePickerIsRange ] = useState ( false ),
		[ open, setOpen ] = useState ( false ),
		onClose = useCallback (
			() => setOpen ( false ),
			[]
		),
		switchToDate = useCallback (
			() => {
				setDatePickerIsRange ( false );
				setOpen ( true );
			},
			[]
		),
		switchToRange = useCallback (
			() => {
				setDatePickerIsRange ( true );
				setOpen ( true );
			},
			[]
		),
		setDate = useCallback (
			date => {
				if ( !isTable || table.closed) {
					dispatch ( actions.tableSetDate ( date, true ) );
				}
				else
				{
					const data = { ...request, ...requestData, date, isRange: datePickerIsRange };
					if ( isGroup ) dispatch ( actions.tableRequest ({ ...data, tableType: datePickerIsRange ? 'summary' : undefined }) );
					else
					{
						if ( datePickerIsRange )
						{
							if (table.prevStateByPeriod) {
								dispatch ( actions.tableRequest ({ ...data, tableType: table.prevStateByPeriod }) );
							} else {
								openSelectTableDialog ( data );
							}
						}
						else
						{
							dispatch ( actions.tableRequest ({ ...data, tableType: undefined }) );
						}
					}
				}
			},
			[ isTable, table, isGroup, datePickerIsRange, request, requestData, dispatch, openSelectTableDialog ]
		);

	return (
		<React.Fragment>
			<MukRangeSwitch {...{ isRange, switchToDate, switchToRange }} />
			<DatePicker
				maxDate={ today }
				isRange={ datePickerIsRange }
				{...{ open, setDate, onClose }}
			/>
		</React.Fragment>
	);
}
