import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { SuggestDropdown } from 'components';
import { useRefreshTableCallback } from 'hooks';
import { useSelector } from 'react-redux';
const dropdowns = [
		'tasks',
		'regions'
	],
	labels = [
		'Задачи',
		'Регион'
	],
	useStyles = makeStyles ({
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			padding: [[ 4, 8, 0, 8 ]]
		},
		buttons: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginTop: 8
		}
	}),
	containerStyles = {
		width: 270,
		marginBottom: 12
	},
	defaultValue = [];


export default React.memo ( Dropdown );

function Dropdown ({ options, values, setFilters, resetFilters })
{
	const classes = useStyles(),
		refreshTable = useRefreshTableCallback(),
		filtersChanged = useSelector ( st => st.filters.changed );

	return (
		<div className={ classes.container }>
			<div>
				{ dropdowns.map ( ( name, i ) => ( options[ name ] &&
					<SuggestDropdown
						key={ name }
						label={ labels[ i ] }
						options={ options[ name ] }
						onChange={ value => setFilters ({ [ name ]: value }) }
						value={ values[ name ] || defaultValue }
						{...{ containerStyles }}
					/>
				) ) }
			</div>
			<div className={ classes.buttons }>
				{ (!!refreshTable || filtersChanged) &&
					<Button color="primary" onClick={ refreshTable }>
						Обновить
					</Button>
				}
				<Button color="primary" onClick={ resetFilters }>
					Сбросить все
				</Button>
			</div>
		</div>
	);
}
